<div class="container mx-auto marginT-20">
  <div class="row">
    <div class="col-12">
      <h3>Yo Aprendo</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let item of listaVideos; let i = index"
      >
      <app-tarjeta-video [item]="item"></app-tarjeta-video>
    </div>
  </div>
</div>

<div [hidden]="cargaCompleta">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p class="texto-blanco">Cargando...</p>
  </ngx-spinner>
</div>
