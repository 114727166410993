<div class="contenedor-tarjeta">
  <div class="row">
    <div class="col">
      <div class="image-container">
        <div
          class="icono-signo my-auto"
          [ngStyle]="{ 'background-image': 'url(' + item.icono + ')' }"
        ></div>
      </div>

      <div class="contenido">
        <div class="row">
          <div class="col-12">
            <div class="texto-titulo">
              {{ item.nombre }}
              <fa-icon class="position-date" [icon]="faArrowRight"></fa-icon>
            </div>
            <div class="texto-info">
              {{ item.rango }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
