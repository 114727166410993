import { UtilService } from './../../services/util.service';
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Optional,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { PuntosInterface } from 'src/app/models/puntos';

import { YoaprendoInterface } from 'src/app/models/yoaprendo';
import { UsuarioInterface } from '../../models/user';
import { BannerInterface } from 'src/app/models/banner';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LinkService } from 'src/app/services/link.service';

@Component({
  selector: 'app-yoaprendo-detalle',
  templateUrl: './yoaprendo-detalle.component.html',
  styleUrls: ['./yoaprendo-detalle.component.scss'],
})
export class YoaprendoDetalleComponent implements OnInit {
  /** Arreglo que almacena la lista de videos */
  listaVideos: YoaprendoInterface[] = [];
  /** Objeto de video a mostrar */
  public videoActivo: YoaprendoInterface;
  /** usuario activo */
  usuario: UsuarioInterface;
  /** Variable para verificar si el usuario esta logueado */
  usuarioLogueado = false;
  /** usuario activo */
  usuarioLocal: UsuarioInterface;
  /** Puntos cuando se guarda */
  puntosGuardar: number = 10;
  /** Puntos cuando se da like */
  puntosLike: number = 5;
  /** Lista de comentarios del video a mostrar */
  public listaComentarios = [];
  /** Almacena el id del video activo */
  public id: any;
  /** Bandera para determinar si se cargo la informacion del video */
  public cargado = false;
  /** Bandera para verificar si el video fue guardada por el usuario */
  save: boolean = false;
  /** Bandera para verificar si video tiene like por parte del usuario*/
  like: boolean = false;
  /** icono del reloj*/
  faClock = faClock;
  /** variable de control para mostrar y ocultar modal de compartir */
  modalCompartir: boolean = false;
  /** Banners de las noticas */
  bannerSuperior: BannerInterface;
  bannerInferior: BannerInterface;
  /** Verifica si el usuario ya comento en el video */
  puedeComentar = true;
  /** video */
  video: any;
  /** Objeto de videos desde localStorage */
  storeVideos: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private title: Title,
    private meta: Meta,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public router: Router,
    private mainService: MainService,
    public utilSevice: UtilService,
    public authService: AuthService,
    private linkService: LinkService,
    public utilService: UtilService,
    @Optional() @Inject('REQUEST') private request: Request
  ) {
    this.storeVideos = this.utilService.dataVideos;
    this.usuarioLocal = this.authService.getUser();
    this.authService.logueado.subscribe((logueado) => {
      this.usuarioLogueado = logueado;
      if (logueado) {
        this.usuarioLocal = this.authService.getUser();
      }
      
    });
    this.route.params.subscribe((params) => {
      this.id = params.id;
      this.utilSevice.startLoading();
      this.getVideo();
    });
  }

  ngOnInit(): void {
    
  }

  setSeo(title, description, image) {
    this.title.setTitle(description);
    this.meta.addTag({ property: 'og:title', content: title }, true);
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:image:alt', content: title });
    this.meta.addTag({ property: 'og:image:alt', content: title });
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:site_name', content: title });
    this.meta.addTag({ name: 'title', content: title });
    this.meta.addTag({ itemprop: 'name', content: title });
    this.linkService.addTag({ rel: 'image_src', href: image });
    this.meta.addTag({ name: 'twitter:image', content: image });
    this.meta.addTag({ itemprop: 'image', content: image });
    this.meta.addTag({ property: 'og:image', content: image });

    this.meta.addTag({ name: 'twitter:description', content: description });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({
      name: 'description',
      property: 'og:description',
      content: description,
    });
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ itemprop: 'description', content: description });

    //this.meta.addTag({ property: 'og:image', itemprop:"image",content: image });
    this.meta.addTag({ property: 'og:type', content: 'article' });
    this.meta.addTag({
      property: 'twitter:card',
      content: 'summary_large_image',
    });
    this.meta.addTag({ property: 'og:image:width', content: '300' });
    this.meta.addTag({ property: 'og:image:height', content: '200' });
    this.meta.addTag({
      property: 'og:url',
      content: 'https://www.yoferretero.com/video' + this.videoActivo.slug,
    });
    this.meta.addTag({ property: 'og:image:secure_url', content: image });
    this.meta.addTag({ name: 'msapplication-TileImage', content: image });
  }

  /**
   * Obtiene el usuario activo para verificar si el video fue guardada o le dieron like
   */
  getUsuario() {
    if (this.usuarioLogueado) {
      this.mainService
        .get('api/user/' + this.usuarioLocal._id)
        .subscribe((user: UsuarioInterface) => {
          this.usuario = user;
          if (
            this.usuario.yoAprendoGuardados === undefined ||
            this.usuario.yoAprendoGuardados === null
          ) {
            this.usuario.yoAprendoGuardados = [];
          }
          
          if (
            this.usuario.yoAprendoLike === undefined ||
            this.usuario.yoAprendoLike === null
          ) {
            this.usuario.yoAprendoLike = [];
          }
          
          let findSave = this.usuario.yoAprendoGuardados.indexOf(
            this.videoActivo._id
          );
          if (findSave !== -1) {
            this.save = true;
          }
          let findLike = this.usuario.yoAprendoLike.indexOf(this.videoActivo._id);
          if (findLike !== -1) {
            this.like = true;
          }
          this.getVideos();
        });
    } else {
      this.getVideos();
    }
  }
  /**
   * Obtener banners de publicidad
   */
  getBanners() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/banner')
        .subscribe((banners: BannerInterface[]) => {
          for (let item of banners) {
            if (item.nombre === 'Superior web yo aprendo') {
              this.bannerSuperior = item;
            }
            if (item.nombre === 'Inferior web yo aprendo') {
              this.bannerInferior = item;
            }
          }
          this.cargado = true;
          this.guardarVisita();
          this.cargado = true;
          this.utilSevice.stopLoading();
        });
    }
  }

  /**
   * Metodo para guardar la visita del usuario
   */
   guardarVisita() {
    if (isPlatformBrowser(this.platformId)) {
      let visita = {
        plataforma: 'web',
        usuario: this.usuarioLocal._id,
        modulo: 'YoAprendo',
        video: this.videoActivo._id,
      };
      this.utilSevice.guardarVisita(visita);
    }
  }

  /**
   * Obtiene la informacion del video a mostrar
   */
  getVideo() {
    this.mainService
      .get('api/yoaprendoSlug/' + this.id)
      .subscribe((video: YoaprendoInterface) => {
        this.videoActivo = video;
        this.setSeo(
          'YoFerretero::Yoaprendo',
          this.videoActivo.titulo,
          this.videoActivo.imagen
        );
        if (
          this.videoActivo.video !== undefined &&
          this.videoActivo.video !== null &&
          this.videoActivo.video !== ''
        ) {
          this.video = this.getVideoIframe(this.videoActivo.video);
        }
        this.getComentarios();
      });
  }

  /**
   * Obtiene lista de comentarios del video a mostrar
   */
  getComentarios() {
    //this.setSeo(this.noticia.titulo, this.noticia.titulo, this.noticia.imagen);
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/comentariosPorVideo/' + this.videoActivo._id)
        .subscribe((comentarios: any) => {
          if (comentarios.success) {
            this.listaComentarios = comentarios.data;
            for (let item of this.listaComentarios) {
              if (item.usuario._id === this.usuarioLocal._id) {
                this.puedeComentar = false;
              }
            }
          }else {
            this.puedeComentar = true;
            this.listaComentarios = [];
          }
          this.getUsuario();
        });
    }
  }
  /**
   * Obtiene lista de noticias publicadas
   */
  getVideos() {
    if (isPlatformBrowser(this.platformId)) {
      let now = new Date();
      this.mainService
        .get('api/yoaprendoPublicadas/' + now)
        .subscribe((videos: YoaprendoInterface[]) => {
          if (this.storeVideos === JSON.stringify(videos)) {
          } else {
            this.utilService.storeVideos(videos);
            this.listaVideos = videos;
          }
          this.getBanners();
        });
    }
  }
  /**
   * Funcion para recargar video si se creo el comentario desde el componente
   * @param event emisor para saber si se creo el comentario desde el componente
   */
  onComentarioCreado(event) {
    if (event === true) {
      this.getVideo();
    }
  }
  /**
   * Metodo para agregar y quitar videos de los guardados del usuario
   */
  toogleSave() {
    if (!this.usuarioLogueado) {
      this.utilSevice.showWarning({
        time: 5000,
        title: 'Alerta',
        msj: 'Inicia sesión para guardar la noticia!',
      });
      return false;
    }
    if (this.save) {
      let findSave = this.usuario.yoAprendoGuardados.indexOf(this.videoActivo._id);
      this.usuario.yoAprendoGuardados.splice(findSave, 1);
      this.usuario.puntos = this.usuario.puntos - this.puntosGuardar;
      this.quitarFavorito();
    } else {
      this.usuario.yoAprendoGuardados.push(this.videoActivo._id);
      this.usuario.puntos = this.usuario.puntos + this.puntosGuardar;
      this.guardarFavorito();
    }
    this.save = !this.save;
  }
  /**
   * Metodo para agregar al modelo de videos guardadas por  el usuario
   */
  guardarFavorito() {
    let data = {
      tipo: 'yoAprendo',
      usuario: this.usuario._id,
      video: this.videoActivo._id,
    };
    this.mainService.post('api/saved', data).subscribe((result) => {
      let puntuacion: PuntosInterface = {
        evento: 'Agregar video a favoritos',
        referencia: this.videoActivo._id,
        usuario: this.usuario._id,
        puntos: this.puntosGuardar,
      };
      this.utilSevice.guardarHistorialDePuntos(puntuacion);
      this.modificarUsuario('saved');
    });
  }
  /**
   * Metodo para quitar al modelo de videos guardadas por  el usuario
   */
  quitarFavorito() {
    this.mainService
      .delete('api/savedVideo/' + this.usuario._id + '/' + this.videoActivo._id)
      .subscribe((result) => {
        let puntuacion: PuntosInterface = {
          evento: 'Quitar video de favoritos',
          referencia: this.videoActivo._id,
          usuario: this.usuario._id,
          puntos: this.puntosGuardar * -1,
        };
        this.utilSevice.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('saved');
      });
  }
  /**
   * Metodo para agregar y quitar like del video por el usuario
   */
  toogleLike() {
    if (!this.usuarioLogueado) {
      this.utilSevice.showWarning({
        time: 5000,
        title: 'Alerta',
        msj: 'Inicia sesión para dar like en la noticia!',
      });
      return false;
    }
    if (this.like) {
      let findLike = this.usuario.yoAprendoLike.indexOf(this.videoActivo._id);
      this.usuario.yoAprendoLike.splice(findLike, 1);
      this.usuario.puntos = this.usuario.puntos - this.puntosLike;
      this.quitarLike();
    } else {
      this.usuario.yoAprendoLike.push(this.videoActivo._id);
      this.usuario.puntos = this.usuario.puntos + this.puntosLike;
      this.guardarLike();
    }
    this.like = !this.like;
  }
  /**
   * Metodo para quitar like del video y del usuario
   */
  quitarLike() {
    let likes = 0;
    if (this.videoActivo.likes !== undefined && this.videoActivo.likes !== null) {
      likes = this.videoActivo.likes;
    }
    likes--;
    let videoUpdate = {
      likes: likes,
    };
    this.mainService
      .put('api/yoaprendo/' + this.videoActivo._id, videoUpdate)
      .subscribe((result) => {
        let puntuacion: PuntosInterface = {
          evento: 'Quitar Like en video',
          referencia: this.videoActivo._id,
          usuario: this.usuario._id,
          puntos: this.puntosLike * -1,
        };
        this.utilSevice.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('like');
        this.videoActivo.likes--;
      });
  }
  /**
   * Metodo para agregar al modelo de videos guardadas por  el usuario
   */
  guardarLike() {
    let likes = 0;
    if (this.videoActivo.likes !== undefined && this.videoActivo.likes !== null) {
      likes = this.videoActivo.likes;
    }
    likes++;
    let videoUpdate = {
      likes: likes,
    };
    this.mainService
      .put('api/yoaprendo/' + this.videoActivo._id, videoUpdate)
      .subscribe((result) => {
        let puntuacion: PuntosInterface = {
          evento: 'Like en video',
          referencia: this.videoActivo._id,
          usuario: this.usuario._id,
          puntos: this.puntosLike,
        };
        this.utilSevice.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('like');
        this.videoActivo.likes++;
      });
  }
  /**
   * Actualizar datos del local storage de usuario
   */
  updateStorage() {
    let token = this.authService.getToken();
    this.authService.storeUserData(token, this.usuario);
  }
  /**
   * Metodo para actualizar datos del usuario al momento de dar like y guardar video
   * @param tipo identifica si se modifico like o guardar video
   */
  modificarUsuario(tipo) {
    this.mainService
      .put('api/user/' + this.usuario._id, this.usuario)
      .subscribe((result: any) => {
        if (result.success) {
          this.updateStorage();
        }
      });
  }
  /**
   * Genera enlace para compartir en redes sociales
   */
  enlaceShared() {
    return this.mainService.baseURLssr + 'video/' + this.videoActivo.slug;
  }
  /**
   * Funcion para carga de video de youtube
   */
  getVideoIframe(url) {
    let video, results;
    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = results === null ? url : results[1];

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/' + video
    );
  }
  /**
   * Evento al compartir
   */
  compartiendo(){
    let puntuacion: PuntosInterface = {
      evento: "Compartir noticia",
      referencia: this.videoActivo._id,
      usuario: this.usuario._id,
      puntos: 5
    };
    this.utilService.guardarHistorialDePuntos(puntuacion);
  }
}
