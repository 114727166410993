import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lista-comentarios',
  templateUrl: './lista-comentarios.component.html',
  styleUrls: ['./lista-comentarios.component.scss']
})
export class ListaComentariosComponent implements OnInit {

  @Input() comentarios;
  constructor() { }

  ngOnInit(): void {
  }

}
