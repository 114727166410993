import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.css']
})
export class ModalLoginComponent implements OnInit {
  /** Emisor para cerrar modal de login */
  @Output() public cerrar = new EventEmitter<boolean>();
  /** Emisor para cerrar modal de login y abrir el de registro */
  @Output() public registro = new EventEmitter<boolean>();
  /** Emisor para cerrar modal de login y abrir el de olvidarContraseña */
  @Output() public olvidar = new EventEmitter<boolean>();
  /** Formulario de control para el login de usuarios */
  public frmLogin: FormGroup;

  /** Variable para controlar si se esta enviando la informacion del formulario */
  public submited = false;

  /** verificar si se carga la informacion */
  cargado: boolean = false;

  /** Variable de control para mostrar u ocultar contraseña */
  mostrar = false;

  /** Variable de control para definir el tipo de input en el campo de clave de usuario */
  tipoInput = 'password';
  constructor(
    public fb: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public utilService: UtilService
  ) {
    this.frmLogin = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit(): void {}
  /**
   * Metodo para abreviar la obtencion de los campos del formulario en la vista html
   */
  get f() {
    return this.frmLogin.controls;
  }
  /**
   * Metodo para la verificacion del login del usuario
   */
  async onIniciar() {
    this.submited = true;
    if (this.frmLogin.invalid) {
      return false;
    }
    this.utilService.startLoading();
    const login = this.frmLogin.value;
    this.authService.authenticateUser(login).subscribe(
      (data: any) => {
        if (data) {
          if (data.errors || !data.success) {
            this.utilService.showWarning({
              title: "Alerta",
              msj: data.msg,
              time: 5000
            });
            this.utilService.stopLoading();
          } else {
            if (data.user) {
              this.utilService.stopLoading();
              if (this.authService.storeUserData(data.token, data.user)) {
                this.utilService.stopLoading();
                this.utilService.showSuccess({
                  title: "¡Genial!",
                  msj: "Se inicio sesión",
                  time: 5000
                });
                this.salir();
              }
            } else {
              this.frmLogin.reset();
              this.utilService.stopLoading();
              this.utilService.showWarning({
                title: "Alerta",
                msj: "Usuario no encontrado",
                time: 5000
              });
            }
          }
        } else {
          this.frmLogin.reset();
          this.utilService.stopLoading();
          this.utilService.showWarning({
            title: "Alerta",
            msj: "No se ha establecido una conexión con el servidor.",
            time: 5000
          });
        }
      },
      error => {
        this.frmLogin.reset();
        this.utilService.stopLoading();
        console.log(error);
        alert("Ocurrio un error.");
      }
    );
  }
  /**
   * Emite evento para cerrar modal del login y abrir el de registro
   */
  onCrearCuenta(){
    this.registro.emit(true);
  }

  /**
   * Emite evento para cerrar modal del login y abrir el de olvidar Contraseña
   */
  onOlvidar(){
    this.olvidar.emit(true);
  }
  /**
   * Metodo para cambiar estado de mostrar u ocultar input de clave del usuario
   */
  toogleIcono(){
    this.mostrar = !this.mostrar;
    this.tipoInput = "password";
    if (this.mostrar) {
      this.tipoInput = "text";
    }
  }
  /**
   * Metodo para salir del modal de login
   */
  salir(){
    this.cerrar.emit(false);
  }
}
