<div class="row">
    <div class="col-12">
      <div class="texto-titulo">Deja tu comentario</div>
      <div class="texto-autor label-length"
      >{{structComentario.comentario.length}}/100
      </div>
    </div>
    <div class="col-12">
      <textarea
        [disabled]="creandoComentario"
        maxlength="100"
        rows="3"
        class="text-area"
        [(ngModel)]="structComentario.comentario"
        placeholder="Escribe aquí tu comentario..."
      ></textarea>
      <button
        [disabled]="creandoComentario"
        expand="block"
        type="button"
        (click)="crearComentario()"
        class="boton-iniciar ml-auto"
      >Enviar
      </button>
    </div>
  </div>
  
  