<div class="row marginT-20">
  <div class="col-4">
    <div
      [ngClass]="{
        'tab-activo': tabActivo === 1,
        tab: tabActivo !== 1
      }"
      (click)="tabActivo = 1"
    >
      Noticias
    </div>
  </div>
  <div class="col-4">
    <div
      [ngClass]="{
        'tab-activo': tabActivo === 2,
        tab: tabActivo !== 2
      }"
      (click)="tabActivo = 2"
    >
      Ofertas
    </div>
  </div>
  <div class="col-4">
    <div
      [ngClass]="{
        'tab-activo': tabActivo === 3,
        tab: tabActivo !== 3
      }"
      (click)="tabActivo = 3"
    >
      Videos
    </div>
  </div>
</div>
<div class="row marginT-20" [hidden]="tabActivo !== 1">
  <div class="col-12">
    <label class="texto">Noticias guardadas</label>

    <div *ngIf="noticiasGuardadas.length === 0">
      <div class="text-center estado-vacio">
        <img src="/assets/img/vacio-guardados.png" class="img-vacio" />
        <h3 class="titulo-vacio">¡Aún no tienes guardados!</h3>
        <p class="texto-vacio">
          Acá puedes guardar las noticias que quieras leer en otro momentos.
          Recuerda que interactuar te da puntos ¡Animate!
        </p>
      </div>
    </div>
    <div *ngIf="noticiasGuardadas.length > 0">
      <div *ngIf="usuarioCargado">
        <div class="row">
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-6 mb-20"
            *ngFor="let item of noticiasGuardadas"
          >
            <tarjeta-noticia
              [item]="item.noticia"
              (modificado)="onActualizarUsuario($event)"
              (modificando)="onModificando($event)"
              [usuarioOb]="emisorUsuario"
            ></tarjeta-noticia>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row marginT-20" [hidden]="tabActivo !== 2">
  <div class="col-12">
    <label class="texto">Ofertas guardadas</label>
    <div *ngIf="ofertasGuardadas.length === 0">
      <div class="text-center estado-vacio">
        <img src="/assets/img/vacio-guardados.png" class="img-vacio" />
        <h3 class="titulo-vacio">¡Aún no tienes guardados!</h3>
        <p class="texto-vacio">
          Acá puedes guardar las ofertas que quieras leer en otro momentos.
          Recuerda que interactuar te da puntos ¡Animate!
        </p>
      </div>
    </div>
  </div>
  <div
    class="col-sm-6 col-12 mb-20"
    *ngFor="let item of ofertasGuardadas; let i = index"
  >
    <a class="w-100" [routerLink]="['/detalle-oferta', item.oferta.slug]">
      <tarjeta-oferta [item]="item.oferta"></tarjeta-oferta>
    </a>
  </div>
</div>
<div class="row marginT-20" [hidden]="tabActivo !== 3">
  <div class="col-12">
    <label class="texto">Videos guardados</label>
    <div *ngIf="videosGuardados.length === 0">
      <div class="text-center estado-vacio">
        <img src="/assets/img/vacio-guardados.png" class="img-vacio" />
        <h3 class="titulo-vacio">¡Aún no tienes guardados!</h3>
        <p class="texto-vacio">
          Acá puedes guardar los videos que quieras ver en otro momentos.
          Recuerda que interactuar te da puntos ¡Animate!
        </p>
      </div>
    </div>
  </div>
  
  <div
    class="col-sm-6 col-12 mb-20"
    *ngFor="let item of videosGuardados; let i = index"
  >
    <a class="w-100" [routerLink]="['/video', item.video.slug]">
      <app-tarjeta-video [item]="item.video"></app-tarjeta-video>
    </a>
  </div>
</div>