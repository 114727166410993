<div class="seccion-noticias">
  <div class="row ">
    <div class="col-12">
      <div class="row">
        <div class="col-10 ">
          <a class="w-100" [routerLink]="['/noticias', item.slug]"  >
          <div
            class="texto-info3">
            {{ item.titulo  }}
          </div>
          </a>
          <div class="row">
            <div class="col-1 ">
              <fa-icon class="icon-clock my-auto" [icon]="faClock"></fa-icon>
            </div>
            <div class="col-1 ">
              <div
                class="texto-info2 my-auto">
                {{item.fechaPublicacion  | datediff}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 col-sm-2 ">
        </div>
      </div>
    </div>
  </div>
</div>
