import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UsuarioInterface } from 'src/app/models/user';
import {Router} from "@angular/router";
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {faClock} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'tarjeta-video-sinimagen',
  templateUrl: './tarjeta-video-sinimagen.component.html',
  styleUrls: ['./tarjeta-video-sinimagen.component.scss']
})
export class TarjetaVideoSinimagenComponent implements OnInit {
  /** Emisor para actualizar datos del usuario */
  @Output() public modificado = new EventEmitter<boolean>();
  /** Datos del video a mostrar */
  @Input() item;
  /** usuario activo */
  usuario: UsuarioInterface;
  /** usuario activo */
  usuarioLocal: UsuarioInterface;
  /** Bandera para verificar si la noticia fue guardada por el usuario */
  save: boolean = false;
  /** Bandera para verificar si la noticia tiene like por parte del usuario*/
  like: boolean = false;
  /** Puntos cuando se guarda */
  puntosGuardar: number = 10;

  /** Puntos cuando se da like */
  puntosLike: number = 5;
  /** icono de la flecha*/
  faArrowRight = faArrowRight;
  /** icono del reloj*/
  faClock = faClock;
  constructor(public router: Router) { }

  ngOnInit(): void {
  }
  redirectTo({slug}){
    this.router.navigateByUrl('/yoaprendo', {skipLocationChange: true}).then(()=>
    this.router.navigate(["/video", slug]));
 }

}
