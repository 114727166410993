<div class="row">
  <div class="col-12 col-sm-3">
    <picture>
      <img [src]="previewE" *ngIf="previewE !== null" class="img-fluid" />
      <img
        [src]="getLinkPicture()"
        *ngIf="previewE === null"
        class="img-fluid"
      />
    </picture>
    <p class="texto-nombre">{{ user.nombre }}</p>
    <div class="div-puntos" *ngIf="puntosMes">
      <i class="fa fa-star"></i><label *ngIf="puntos">{{ puntosMes }}</label>
    </div>
  </div>
  <div class="col-12 col-sm-9">
    <div class="row">
      <div class="col-12">
        <h3 class="titulo">Mis datos</h3>
      </div>
    </div>
    <form [formGroup]="frmEdit" (ngSubmit)="guardar()" novalidate>
      <div class="row">
        <div class="col-sm-6 col-12">
          <label class="control-label texto-label">Nombre completo</label>
          <input
            class="form-control input-login"
            formControlName="nombre"
            [(ngModel)]="user.nombre"
            [ngClass]="{ 'is-invalid': submited && f.nombre.errors }"
            type="text"
            placeholder="Escribe aquí tu nombre"
          />
          <i class="fa fa-user icono-input"></i>
          <div *ngIf="submited && f.nombre.errors" class="invalid-feedback">
            <div *ngIf="f.nombre.errors.required">Nombre requerido</div>
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <label class="control-label texto-label">Email</label>
          <input
            readOnly="true"
            class="form-control input-login"
            formControlName="email"
            [(ngModel)]="user.email"
            type="email"
            placeholder="Escribe aquí tu email"
          />
          <i class="fa fa-envelope icono-input"></i>
        </div>
        <div class="col-sm-6 col-12">
          <label class="control-label texto-label">Celular</label>
          <input
            type="tel"
            placeholder="Escribe aquí tu celular"
            class="form-control input-login"
            formControlName="celular"
            [(ngModel)]="user.celular"
            [ngClass]="{ 'is-invalid': submited && f.celular.errors }"
          />
          <i class="fa fa-mobile icono-input"></i>
          <div *ngIf="submited && f.celular.errors" class="invalid-feedback">
            <div *ngIf="f.celular.errors.required">Celular requerido</div>
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <label
            class="control-label texto-label"
            *ngIf="user.tipo === 'Ferretero'"
            >Ferreteria</label
          >
          <label
            class="control-label texto-label"
            *ngIf="user.tipo === 'Proveedor'"
            >Marca</label
          >
          <input
            class="form-control input-login"
            formControlName="ferreteria"
            [(ngModel)]="user.ferreteria"
            [ngClass]="{ 'is-invalid': submited && f.ferreteria.errors }"
            type="text"
            placeholder="Escribe aquí"
          />
          <i class="fa fa-institution icono-input"></i>
          <div *ngIf="submited && f.ferreteria.errors" class="invalid-feedback">
            <div
              *ngIf="f.ferreteria.errors.required && user.tipo === 'Ferretero'"
            >
              Ferreteria requerido
            </div>
            <div
              *ngIf="f.ferreteria.errors.required && user.tipo === 'Proveedor'"
            >
              Marca requerida
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <label class="control-label texto-label">Cargo</label>
          <select
            formControlName="cargo"
            [(ngModel)]="user.cargo"
            class="form-control input-login"
            [ngClass]="{ 'is-invalid': submited && f.cargo.errors }"
            *ngIf="user.tipo === 'Ferretero'"
          >
            <option value="">Elige una opción</option>
            <option [value]="item" *ngFor="let item of optFerretero">
              {{ item }}
            </option>
          </select>
          <select
            formControlName="cargo"
            [(ngModel)]="user.cargo"
            class="form-control input-login"
            [ngClass]="{ 'is-invalid': submited && f.cargo.errors }"
            *ngIf="user.tipo === 'Proveedor'"
          >
            <option value="">Elige una opción</option>
            <option [value]="item" *ngFor="let item of optProveedor">
              {{ item }}
            </option>
          </select>
          <div *ngIf="submited && f.cargo.errors" class="invalid-feedback">
            <div *ngIf="f.cargo.errors.required">Cargo requerido</div>
          </div>
        </div>

        <div class="col-sm-6 col-12">
          <div class="row">
            <div class="col-12">
              <label class="control-label texto-label">Foto</label><br />
              <input
                hidden
                type="file"
                id="img-segundariasE"
                (change)="inputImagenE($event)"
              />
              <button
                (click)="abrirArchivoE()"
                type="button"
                class="boton-bordes"
              >
                <i class="fa fa-camera" aria-hidden="true"></i> Cambiar foto
              </button>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-sm-6"
          [formGroup]="frmEdit"
          *ngIf="ciudades.length > 0"
        >
          <label class="control-label texto-label">Ciudad</label>
          <ng-autocomplete
            [data]="ciudades"

            formControlName="ubicacion"
            [(ngModel)]="user.ubicacion"
            [ngClass]="{ 'is-invalid': submited && f.ubicacion.errors }"
            [searchKeyword]="keyword"
            (selected)="selectEvent($event)"
            [itemTemplate]="itemTemplateC"
          >
          </ng-autocomplete>

          <ng-template #itemTemplateC let-item>
            <a [innerHTML]="item.ciudad">hola</a>
          </ng-template>
          <div *ngIf="submited && f.ubicacion.errors" class="invalid-feedback">
            <div *ngIf="f.ubicacion.errors.required">Ciudad requerida</div>
          </div>
        </div>
      </div>
      <div class="row marginT-20">
        <div class="col-6">
          <button type="button" class="boton-bordes" (click)="onCambiarClave()">
            <i class="fa fa-password" aria-hidden="true"></i> Cambiar contraseña
          </button>
        </div>
        <div class="col-6">
          <button expand="block" type="submit" class="boton-guardar">
            Guardar cambios
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
