import { MainService } from 'src/app/services/main.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UsuarioInterface } from 'src/app/models/user';
import { Observable } from 'rxjs';
import { PuntosInterface } from 'src/app/models/puntos';

@Component({
  selector: 'app-mis-puntos',
  templateUrl: './mis-puntos.component.html',
  styleUrls: ['./mis-puntos.component.scss']
})
export class MisPuntosComponent implements OnInit {
  /** EMite puntos de usuario por mes */
  @Output() public puntos = new EventEmitter<number>();
  /** Usuario enviado desde el perfil */
  @Input() public usuario:  UsuarioInterface;
  @Input() public userObser:  Observable<UsuarioInterface>;
  /** Variable de control para verificar carga de informacion */
  cargado= false;
  /** Lista de eventos y putos del usuario */
  historialDePuntos: PuntosInterface[];
  /** Puntos del mes */
  puntosMes= 0;
  constructor( public mainService: MainService) { }

  ngOnInit(): void {
    this.userObser.subscribe(data => {
      this.usuario = data;
      this.getPuntos();
    });
    this.getPuntos();
  }
  /**
   * Obtiente lista de puntos del mes y calcula los puntos en el mes
   */
  getPuntos() {
    this.mainService.get('api/puntosUserMes/' + this.usuario._id).subscribe( (list: any) => {
      if (list.success){
        let pun = 0;
        for (let item of list.data) {
          pun = pun + item.puntos;
        }
        this.puntosMes = pun;
        this.historialDePuntos = list.data;
      } else {
        this.historialDePuntos = [];
      }
      this.puntos.emit(this.puntosMes);
      this.cargado = true;
    });
  }
}
