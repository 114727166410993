import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, Input, PLATFORM_ID, Inject } from '@angular/core';
import { UsuarioInterface } from 'src/app/models/user';
import { OfertasInterface } from 'src/app/models/ofertas';
import { Observable, BehaviorSubject } from 'rxjs';
import { MainService } from 'src/app/services/main.service';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-ferreofertas',
  templateUrl: './ferreofertas.component.html',
  styleUrls: ['./ferreofertas.component.css']
})
export class FerreofertasComponent implements OnInit {
  /** Variable para verificar cuando se loguee el usuario */
  usuarioLogueado = false;
  /** Bandera para determinar loadig de carga de datos*/
  cargado: boolean = false;
  /** Emisor para actualizar menu lateral */
  @Output() public cargaCompleta = new EventEmitter<boolean>();
  /** Lista de noticias fijas */
  public ofertas: OfertasInterface[];
  /** Emisor para actualizar menu superior en el home */
  @Output() public detalleOferta = new EventEmitter<boolean>();
  /** Bandera para determinar si muestra detalle de horoscopo seleccionado */
  detalle = false;
  /** Oferta seleccionada para mostrar detalle */
  ofertaSeleccionada: OfertasInterface;
  /** Variable que determina si oculta detalle desde el home */
  @Input() public datoDetalle: Observable<any>;
  /** Variable para casos donde se quiere acceder directo al detalle */
  @Input() public irOferta;
  /** Datos de usuario */
  usuarioLocal: UsuarioInterface;
  /** Redirige en caso de pedir detalle de oferta */
  idOferta = null;
  /** Emisor para noticias */
  emisorOferta = new BehaviorSubject({});
  /** Posicion de la oferta seleccionada */
  pos = 0;
  /** Objeto de ofertas desde localStorage */
  storeOfertas: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public sanitizer: DomSanitizer,
    private title: Title, private meta: Meta,
    public mainService: MainService,
    public utilService: UtilService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.usuarioLocal = this.authService.getUser();
    this.authService.logueado.subscribe(logueado => {
      this.usuarioLogueado = logueado;
      if (logueado) {
        this.usuarioLocal = this.authService.getUser();
      }
    });
    /*this.route.params.subscribe(params => {
      if (params.oferta !== undefined) {
        this.idOferta = params.oferta;
      }
    });*/
    this.utilService.startLoading();
    if (isPlatformBrowser(this.platformId)) {
      this.storeOfertas = this.utilService.loadOfertas();
      if (this.storeOfertas !== false) {
        this.ofertas = JSON.parse(this.storeOfertas);
        /*if (this.idOferta !== null) {
          let pos = 0;
          for (let item of this.ofertas) {
            if (item._id === this.idOferta) {
              this.seleccionar({ pos: pos });
              this.setSeo(item.nombre, 'descripcion oferta', item.imagenes[0].url);
              break;
            }
            pos++;
          }
        }*/
        let self = this;
        setTimeout(function () {
          self.cargado = true;
          self.utilService.stopLoading();
        }, 100);
      }
    }
  }
  ngOnInit() {
    this.setSeo('YO FERRETERO :: La Aplicación Del Ferretero ::Ferreofertas', 'Herramienta para comunicar los descuentos y ofertas de las marcas de forma práctica y directa', this.mainService.baseURL + 'assets/img/logo-login.png');
    this.getOfertas();
  }
  /**
   * Establece los patametros del SEO para la oferta
   */
  setSeo(title, description, image) {
    this.title.setTitle(title);
    this.meta.addTag({ property: 'og:title', content: title }, true);
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:image:alt', content: title });
    this.meta.addTag({ property: 'og:image:alt', content: title });
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ name: 'title', content: title });
    this.meta.addTag({ itemprop: 'name', content: title });

    this.meta.addTag({ name: 'twitter:image', content: image });

    this.meta.addTag({ name: 'twitter:description', content: description });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ itemprop: 'description', content: description });

    this.meta.addTag({ property: 'og:image', itemprop:"image",content: image });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ property: 'og:image:width', content: '300' });
    this.meta.addTag({ property: 'og:image:height', content: '200' });
    this.meta.addTag({ property: 'og:url', content: 'https://www.yoferretero.com/ferreofertas' });
  }
  /**
   * Metodo para obtener lista de ofertas a mostrar dependiendo de la caducidad de la oferta
   */
  getOfertas() {
    let Hoy = moment();
    this.mainService
      .get('api/ofertasCaducidad/' + Hoy.format('YYYY-MM-DD'))
      .subscribe((data: OfertasInterface[]) => {
        if (this.storeOfertas === JSON.stringify(data)) {

        } else {
          this.ofertas = data;
          if (isPlatformBrowser(this.platformId)) {
            this.utilService.storeOfertas(data);
          }
          /*if (this.idOferta !== null) {
            let pos = 0;
            for (let item of this.ofertas) {
              if (item._id === this.idOferta) {
                this.seleccionar({ pos: pos });
                this.setSeo(item.nombre, 'desctipcion oferta', item.imagenes[0].url);
                break;
              }
              pos++;
            }
          }*/
        }
        if (!this.cargado) {
          this.cargado = true;
          this.utilService.stopLoading();
        }

      });
  }
  /**
   * Metodo para obtener lista de ofertas a mostrar dependiendo de la caducidad de la oferta
   */
  /*getOfertasActualiza() {
    let Hoy = moment();
    this.mainService
      .get('api/ofertasCaducidad/' + Hoy.format('YYYY-MM-DD'))
      .subscribe((data: OfertasInterface[]) => {
        this.ofertas = data;
        this.emisorOferta.next(data);
        this.utilService.stopLoading();
        this.seleccionar({ pos: this.pos });
      });
  }*/
  /**
   * Seleccion el oferta a mostrar el detalle
   * @param param0 Determina el item de la lista de ofertas a seleccionar para mostrar
   */
  /*seleccionar({ pos }) {
    this.ofertaSeleccionada = this.ofertas[pos];
    this.emisorOferta.next(this.ofertaSeleccionada);
    this.detalle = true;
    this.guardarVisita();
    this.pos = pos;
  }*/
  /**
   * ver detalle de la oferta
   */
  verDetalle({ id }) {
    this.router.navigate(['/detalle-oferta', id]);
  }
  /**
   *
   * @param evt boolean que me indica que de ocultar detalle de oferta seleccionada
   */
  onVolverDetalle(evt) {
    if (evt) {
      this.detalle = false;
    }
  }
  /**
   * Receptor de modificacion de oferta
   */
  onModificarOFerta(event) {
    //this.getOfertasActualiza();
  }
}
