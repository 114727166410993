/* pipes.modules.ts */
import { NgModule } from '@angular/core';

import { DatediffPipe } from './datediff.pipe';
import { ShortnumberPipe } from './shortnumber.pipe';

@NgModule({
  declarations: [DatediffPipe, ShortnumberPipe],
  exports: [DatediffPipe, ShortnumberPipe]
})
export class PipesModule {}
