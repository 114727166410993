import { TarjetaNoticiaComponent } from './tarjeta-noticia.component';

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DatediffPipe } from 'src/app/pipes/datediff.pipe';
import { PipesModule } from 'src/app/pipes/pipes.module';
import {ShareModule} from "ngx-sharebuttons";
import {ShareButtonsModule} from "ngx-sharebuttons/buttons";
import { RouterModule } from '@angular/router';



@NgModule({
  imports: [RouterModule ,CommonModule, PipesModule, ShareModule, ShareButtonsModule],
  declarations: [TarjetaNoticiaComponent],
  exports: [TarjetaNoticiaComponent]
})
export class TarjetaNoticiaModule {}
