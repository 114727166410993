import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UsuarioInterface} from "../../../models/user";
import {Router} from "@angular/router";
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {faClock} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'tarjeta-noticia-sinimagen',
  templateUrl: './tarjeta-noticia-sinimagen.component.html',
  styleUrls: ['./tarjeta-noticia-sinimagen.component.scss']
})
export class TarjetaNoticiaSinimagenComponent implements OnInit {

  /** Emisor para actualizar datos del usuario */
  @Output() public modificado = new EventEmitter<boolean>();
  /** Datos de la noticia a mostrar */
  @Input() item;
  /** usuario activo */
  usuario: UsuarioInterface;
  /** usuario activo */
  usuarioLocal: UsuarioInterface;
  /** Bandera para verificar si la noticia fue guardada por el usuario */
  save: boolean = false;
  /** Bandera para verificar si la noticia tiene like por parte del usuario*/
  like: boolean = false;
  /** Puntos cuando se guarda */
  puntosGuardar: number = 10;

  /** Puntos cuando se da like */
  puntosLike: number = 5;
  /** icono de la flecha*/
  faArrowRight = faArrowRight;
  /** icono del reloj*/
  faClock = faClock;

  constructor(
    private router: Router,
    //public mainService: MainService,
    //public authService: AuthService
  ) {
    //this.usuarioLocal = this.authService.getUser();
  }

  ngOnInit() {

  }

  /**
   * Obtiene el usuario activo para verificar si la noticia fue guardada o le dieron like
   */

  /**
   * Redirije a la vista de detalle de noticia
   * @param param0  indica el id de la noticia a mostrar
   */
  onDetalleNoticia({id}) {
    this.router.navigate(["/noticias", id]);
  }

  /**
   * Metodo para agregar y quitar noticia de los guardados del usuario
   */

  /**
   * Metodo para agregar al modelo de noticias guardadas por  el usuario
   */

  /**
   * Metodo para quitar al modelo de noticias guardadas por  el usuario
   */

  /**
   * Metodo para agregar y quitar like de la noticia por el usuario
   */

  /**
   * Metodo para agregar al modelo de noticias guardadas por  el usuario
   */

  /**
   * Metodo para quitar like de noticias y del usuario
   */

  /**
   * Metodo para guardar el eventos y los puntos del usuario
   * @param puntuacion objeto de puntuacion para el historia de puntos del usuario
   */

  /**
   * Actualizar datos del local storage de usuario
   */


}
