<div class="container mx-auto margintT-20 marginB-20">
  <div [hidden]="detalle === true || !cargado">
    <div class="row marginT-20 marginB-20">
      <div class="col-12">
        <h3>Disfruta de las mejores ofertas del sector</h3>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-20"
        *ngFor="let item of ofertas; let i = index"
      >
        <a class="w-100" [routerLink]="['/detalle-oferta', item.slug]">
          <tarjeta-oferta [item]="item"></tarjeta-oferta>
        </a>
      </div>
    </div>
  </div>

  <div [hidden]="cargado">
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="medium"
      color="#fff"
      type="square-jelly-box"
      [fullScreen]="true"
    >
      <p class="texto-blanco">Cargando...</p>
    </ngx-spinner>
  </div>

  <div [hidden]="detalle === false">
    <div *ngIf="detalle">
      <detalle-oferta
        [obserOferta]="emisorOferta"
        (volver)="onVolverDetalle($event)"
        (modificado)="onModificarOFerta($event)"
      ></detalle-oferta>
    </div>
  </div>
</div>
