import {Component, Input, OnInit} from '@angular/core';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'tarjeta-horoscopo',
  templateUrl: './tarjeta-horoscopo.component.html',
  styleUrls: ['./tarjeta-horoscopo.component.scss']
})
export class TarjetaHoroscopoComponent implements OnInit {
  /** item con el detalle del horoscopo*/
  @Input() item;
  /** icono de la flecha*/
  faArrowRight = faArrowRight;

  constructor() {
  }

  ngOnInit(): void {
  }

}
