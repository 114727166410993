import { MainService } from './../../../services/main.service';
import { Component, OnInit, EventEmitter, Output, PLATFORM_ID, Inject } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { BannerInterface } from 'src/app/models/banner';
import { isPlatformBrowser, isPlatformServer} from '@angular/common';
@Component({
  selector: 'modal-bienvenida',
  templateUrl: './modal-bienvenida.component.html',
  styleUrls: ['./modal-bienvenida.component.css']
})
export class ModalBienvenidaComponent implements OnInit {
  /** Emisor para cerrar modal de login */
  @Output() public cerrar = new EventEmitter<boolean>();
  /** Banner de bienvenida */
  banner: BannerInterface;
  /** verifica si el banner fue cargado */
  cargado = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public mainService: MainService,
    public utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.getBanner();
  }
  /**
   * Obtener banners de noticias
   */
  getBanner() {
    this.mainService.get("api/banner-name/Bienvenida").subscribe((banners: BannerInterface) => {
      this.banner = banners;
      this.cargado = true;
      let self = this;
      setTimeout(function() {
        self.salir();
      }, 8000);
    });
  }
  /**
   * Metodo para salir del modal de login
   */
  quitar(){
    if ( isPlatformBrowser(this.platformId)) {
      localStorage.setItem('bannerYFweb', 'true');
    }
    this.cerrar.emit(false);
  }
  /**
   * Quitan modal para que no aparezca en proximas recargas
   */
  /**
   * Metodo para salir del modal de login
   */
  salir(){
    this.cerrar.emit(false);
  }
}
