import { UsuarioInterface } from './../../../models/user';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'modal-cambiar-clave',
  templateUrl: './modal-cambiar-clave.component.html',
  styleUrls: ['./modal-cambiar-clave.component.css']
})
export class ModalCambiarClaveComponent implements OnInit {
  /** Usuario activo */
  usuarioLocal: UsuarioInterface;
  /** Emisor para cerrar modal de login */
  @Output() public cerrar = new EventEmitter<boolean>();
  /** Formulario para cambio de contraseña */
  public frmOlvide: FormGroup;
  /** Variable para controlar si se esta enviando la informacion del formulario */
  public submited = false;
  /** verificar si se carga la informacion */
  cargado: boolean = false;
  /** Variable de control para mostrar u ocultar contraseña */
  mostrar = false;
  /** Variable de control para definir el tipo de input en el campo de clave de usuario */
  tipoInput = 'password';
  /** Variable de control para mostrar u ocultar contraseña anterior*/
  mostrarNew = false;
  /** Variable de control para definir el tipo de input en el campo de clave anterior de usuario */
  tipoInputNew = 'password';
  constructor(
    public fb: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public utilService: UtilService
  ) {
    this.usuarioLocal = this.authService.getUser();
    this.frmOlvide = this.fb.group({
      password: ["", [Validators.required]],
      passwordNueva: ["", [Validators.required]]
    });
  }

  ngOnInit(): void {}
  /**
   * Cambar estado de icono de mostrar/ocultar password actual
   */
  toogleIcono() {
    this.mostrar = !this.mostrar;
    this.tipoInput = "password";
    if (this.mostrar) {
      this.tipoInput = "text";
    }
  }
  /**
   * Cambar estado de icono de mostrar/ocultar password actual
   */
  toogleIcono2() {
    this.mostrarNew = !this.mostrarNew;
    this.tipoInputNew = "password";
    if (this.mostrarNew) {
      this.tipoInputNew = "text";
    }
  }
  /**
   * Metodo para abreviar la obtencion de los campos del formulario en la vista html
   */
  get f() {
    return this.frmOlvide.controls;
  }
  /**
   * Metodo para la verificacion del login del usuario
   */
  async onEnviar() {
    this.submited = true;
    if (this.frmOlvide.invalid) {
      return false;
    }

    this.utilService.startLoading();
    const data = this.frmOlvide.value;
    data.email = this.usuarioLocal.email;
    this.authService.changePassword(data).subscribe(
      (resp: any) => {
        if (resp.success) {
          this.utilService.showSuccess({
            title: "Alerta",
            msj: resp.msg,
            time: 5000
          });
          this.submited = false;
          this.utilService.stopLoading();
          this.frmOlvide.reset();
          this.submited = false;
        } else {
          this.utilService.showWarning({
            title: "Alerta",
            msj: resp.msg,
            time: 5000
          });
          this.submited = false;
          this.utilService.stopLoading()
          this.frmOlvide.reset();
        }
      },
      error => {
        this.frmOlvide.reset();
        this.utilService.stopLoading();
        console.log(error);
      }
    );
  }

  /**
   * Metodo para salir del modal de login
   */
  salir(){
    this.cerrar.emit(false);
  }
}
