import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { UtilService } from 'src/app/services/util.service';



@Component({
  selector: 'modal-contacto-home',
  templateUrl: './modal-contacto-home.component.html',
  styleUrls: ['./modal-contacto-home.component.css']
})
export class ModalContactoHomeComponent implements OnInit {

  /** variable de control para salir del detalle */
  @Output() public enviadoCerrar = new EventEmitter<boolean>();
  /** Objeto con los atributo to, subject y titulo */
  @Input() param;
  /** modelo para el comentario */
  comentario: string = '';
  /** Objeto para control de datos del formulario de envio */
  public frmEnvio: FormGroup;
  /** Bandera para evaluar si se esta enviando el formulario de datos */
  public submited = false;
  /** Verifica si se esta enviando el email */
  enviando= false;
  constructor(
    public mainService: MainService,
    public fb: FormBuilder,
    public utilService: UtilService
  ) {
    this.frmEnvio = this.fb.group({
      nombre: ['', [Validators.required]],
      celular: ['', [Validators.required]],
      email: ['', [Validators.required]],
      nit: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      comentario: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {}
  /**
   * Funcion para abreviar la obtencion de valores de campos del formulario
   */
  get f() {
    return this.frmEnvio.controls;
  }
  /**
   * Funcion para enviar cotización
   */
  onEnviar() {
    this.submited = true;
    if (this.frmEnvio.invalid) {
      return false;
    }
    let datos = this.frmEnvio.value;
    let mensaje =
      '<b>Nombre;</b>' +
      datos.nombre +
      '<br><b>NIT:</b>' +
      datos.nit +
      '<br><b>Teléfono:</b>' +
      datos.celular +
      '<br><b>Ciudad:</b>' +
      datos.ciudad +
      '<br><b>Comentario:</b>' +
      datos.comentario;
    let dataEmail = {
      to: this.param.to,
      from: datos.email,
      subject: this.param.subject,//'Cotización FerreOferta - YoFerretero',
      contenido: mensaje,
      titulo: this.param.titulo,//'Cotización',
      cc: this.utilService.adminEmailCC
    };
    this.enviando = true;
    this.utilService.startLoading();
    this.mainService.sendEmail(dataEmail).subscribe((res: any) => {
      if (res.success) {
        this.utilService.showSuccess({title: "¡Genial!", msj: 'Tu mensaje fue enviado, pronto se pondrán en contacto contigo.' , time: 5000})
      } else {
        this.utilService.showWarning({title: "Alerta", msj: 'Ocurrio un error al enviar el correo, intentalo de nuevo.' , time: 5000})
      }
      this.enviando =  false;
      this.utilService.stopLoading();
      this.frmEnvio.reset();
      this.submited = false;
      this.comentario = '';
      this.cerrarModal(true);
    });
  }
  /**
   * Cerrar modal de contacto
   */
  cerrarModal(msj){
    if (msj) {
      let self = this;
      setTimeout(function() {
        self.enviadoCerrar.emit(true);
      }, 5000);
    } else {
      this.enviadoCerrar.emit(false);
    }

  }

}
