import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PuntosInterface } from 'src/app/models/puntos';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'crear-comentario-video',
  templateUrl: './crear-comentario-video.component.html',
  styleUrls: ['./crear-comentario-video.component.scss']
})
export class CrearComentarioVideoComponent implements OnInit {
  /** Emisor para actualizar menu lateral */
  @Output() public comentarioCreado = new EventEmitter<boolean>();
  /** Objeto usuario */
  public usuarioActivo: any = null;
  /** Datos del video */
  @Input() video;
  /** Bandera para skeleton de cargar comentario */
  public creandoComentario = false;
  /** Variable de control para loading ionic */
  loading: any;
  /** Structura de comentario nuevo */
  public structComentario = {
    comentario: "",
    usuario: "",
    video: ""
  };
  /** Usuario logueado */
  logueado = false;
  constructor(private mainService: MainService,
    public router: Router,
    public utilService: UtilService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.authService.logueado.subscribe(logueado => {
      this.logueado = logueado;
      if (logueado) {
        this.usuarioActivo = this.authService.getUser();
      }
    });
    this.structComentario.usuario = this.usuarioActivo._id;
    this.structComentario.video = this.video._id;
  }

  /**
   * Crear un nuevo comentario en el video
   */
   crearComentario() {
    this.utilService.startLoading();
    if (this.structComentario.comentario === "") {
      this.utilService.showWarning({
        msj: 'Debe escribir el comentario a enviar', title: 'Alerta', time: 3000
      });
      return false;
    }
    this.creandoComentario = true;
    this.mainService
      .post("api/comentarioVideo", this.structComentario)
      .subscribe((resp: any) => {
        if (resp.success) {
          this.updateVideo({ id: resp.data._id });
          let puntuacion: PuntosInterface = {
            evento: "Comentario en video",
            referencia: this.video._id,
            usuario: this.usuarioActivo._id,
            puntos: 20
          };
          this.utilService.guardarHistorialDePuntos(puntuacion);
        } else {
          this.utilService.startLoading();
          this.creandoComentario = false;
          this.utilService.showWarning({
            title: "Alerta",
            msj: "Ocurrio un error por favor intentalo de nuevo.",
            time: 5000
          });

        }
      });
  }
  /**
   * Agrega id del comentario en el video
   * @param param0 id deñ nuevo comentario para asignar en la noticia
   */
   updateVideo({ id }) {
    if (this.video.Comentarios === undefined || this.video.Comentarios === null) {
      this.video.Comentarios = [];
    }
    this.video.Comentarios.push(id);
    this.mainService
      .put("api/yoaprendo/" + this.video._id, this.video)
      .subscribe((res: any) => {
        this.creandoComentario = false;
        if (res.success) {
          this.utilService.stopLoading();
          this.structComentario.comentario = "";
          this.comentarioCreado.emit(true);
          this.utilService.showSuccess({
            title: "Notificación",
            msj: "Se creo comentario con éxito",
            time: 5000
          });
        } else {
          this.utilService.stopLoading();
          this.utilService.showWarning({
            title: "Alerta",
            msj: "Ocurrio un error por favor intentalo de nuevo.",
            time: 5000
          });
        }
      });
  }

}
