import { AuthService } from './../../../services/auth.service';
import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { MainService } from "src/app/services/main.service";
import { UtilService } from 'src/app/services/util.service';
import { Router } from '@angular/router';
import { PuntosInterface } from 'src/app/models/puntos';

@Component({
  selector: 'crear-comentario',
  templateUrl: './crear-comentario.component.html',
  styleUrls: ['./crear-comentario.component.scss']
})
export class CrearComentarioComponent implements OnInit {
  /** Emisor para actualizar menu lateral */
  @Output() public comentarioCreado = new EventEmitter<boolean>();
  /** Objeto usuario */
  public usuarioActivo: any = null;
  /** Datos de la noticia */
  @Input() noticia;
  /** Bandera para skeleton de cargar comentario */
  public creandoComentario = false;
  /** Variable de control para loading ionic */
  loading: any;
  /** Structura de comentario nuevo */
  public structComentario = {
    comentario: "",
    usuario: "",
    noticia: ""
  };
  /** Usuario logueado */
  logueado = false;
  constructor(
    private mainService: MainService,
    public router: Router,
    public utilService: UtilService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.logueado.subscribe(logueado => {
      this.logueado = logueado;
      if (logueado) {
        this.usuarioActivo = this.authService.getUser();
      }
    });
    this.structComentario.usuario = this.usuarioActivo._id;
    this.structComentario.noticia = this.noticia._id;
  }

  /**
   * Crear un nuevo comentario en la noticia activa
   */
  crearComentario() {
    this.utilService.startLoading();
    if (this.structComentario.comentario === "") {
      this.utilService.showWarning({
        msj: 'Debe escribir el comentario a enviar', title: 'Alerta', time: 3000
      });
      return false;
    }
    this.creandoComentario = true;
    this.mainService
      .post("api/comentario", this.structComentario)
      .subscribe((resp: any) => {
        if (resp.success) {
          this.updateNoticia({ id: resp.data._id });
          let puntuacion: PuntosInterface = {
            evento: "Comentario en noticia",
            referencia: this.noticia._id,
            usuario: this.usuarioActivo._id,
            puntos: 20
          };
          this.utilService.guardarHistorialDePuntos(puntuacion);
        } else {
          this.utilService.startLoading();
          this.creandoComentario = false;
          this.utilService.showWarning({
            title: "Alerta",
            msj: "Ocurrio un error por favor intentalo de nuevo.",
            time: 5000
          });

        }
      });
  }
  /**
   * Agrega id del comentario en la noticia
   * @param param0 id deñ nuevo comentario para asignar en la noticia
   */
  updateNoticia({ id }) {
    if (this.noticia.Comentarios === undefined || this.noticia.Comentarios === null) {
      this.noticia.Comentarios = [];
    }
    this.noticia.Comentarios.push(id);
    this.mainService
      .put("api/noticia/" + this.noticia._id, this.noticia)
      .subscribe((res: any) => {
        this.creandoComentario = false;
        if (res.success) {
          this.utilService.stopLoading();
          this.structComentario.comentario = "";
          this.comentarioCreado.emit(true);
          this.utilService.showSuccess({
            title: "Notificación",
            msj: "Se creo comentario con éxito",
            time: 5000
          });
        } else {
          this.utilService.stopLoading();
          this.utilService.showWarning({
            title: "Alerta",
            msj: "Ocurrio un error por favor intentalo de nuevo.",
            time: 5000
          });
        }
      });
  }
}
