import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

interface Sound {
  key: string;
  asset: string;
}

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private sounds: Sound[] = [];
  private audioPlayer: HTMLAudioElement;
  private audioBackground: HTMLAudioElement ;

  constructor(@Inject(PLATFORM_ID) private platformId,) {
    if (isPlatformBrowser(this.platformId)) {
      this.audioPlayer = new Audio();
      this.audioBackground = new Audio();
      this.preload('correcta', '/assets/sonidos/correcta.mp3');
      this.preload('llamada', '/assets/sonidos/Llamada-comodin.mp3');
      this.preload('perdio', '/assets/sonidos/incorrecta.mp3');
      this.preload('gano', '/assets/sonidos/200-puntos.mp3');
      this.preload('nivel', '/assets/sonidos/subir-nivel.mp3');
      this.preload('apertura', '/assets/sonidos/apertura.mp3');
      this.preload('50-50', '/assets/sonidos/50-50.mp3');
      this.preload('publico', '/assets/sonidos/publico.mp3');
    }
  }
  /**
   * Precarga de sonidos para la web
   * @param key identificador para el audio
   * @param asset origen del audio
   */
  preload(key: string, asset: string): void {
    if (isPlatformBrowser(this.platformId)) {
      let audio = new Audio();
      audio.src = asset;
      this.sounds.push({
        key: key,
        asset: asset,
      });
    }
  }/**
   * Ejecutar sonido enviado como parametros
   * @param key identificador del sonido a ejeuctar
   */
  play(key: string): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      let soundToPlay = this.sounds.find((sound) => {
        return sound.key === key;
      });
      this.audioPlayer.src = soundToPlay.asset;
      return new Promise(resolve => this.audioPlayer.play().then(resolve));
    }
  }
  /**
   * Precarga y ejecutar sonido de forndo
   * @param key Identificador del audio
   * @param asset oringen del audio
   */
  preloadComplex(key: string, asset: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.audioBackground.loop = true;
      this.audioBackground.src = asset;
      this.audioBackground.volume = 0.4;
      return new Promise(resolve => this.audioBackground.play().then(resolve));
    }
  }
  /**
   * Detener sonido de fondo
   * @param key identificador del sonido de fondo
   */
  stopSound(key) {
    if (isPlatformBrowser(this.platformId)) {
      return this.audioBackground.pause();
    }
  }
  playFondo() {
    if (isPlatformBrowser(this.platformId)) {
      this.preloadComplex('fondo', '/assets/sonidos/fondo-2.mp3');
    }
  }
}
