<div class="row">
  <div class="col-12">
    <h3 class="titulo">
      ¡Recuerda que entre más interactúes con la plataforma más puntos ganas!
    </h3>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <label class="sub-titulo">Mis puntos</label>
    <div class="div-puntos">
      {{ puntosMes }}
    </div>
  </div>
</div>
<div class="row marginT-20">
  <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th>#</th>
          <th>Actividad</th>
          <th>Fecha</th>
          <th>Puntos</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of historialDePuntos; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ item.evento }}</td>
          <td>{{ item.createdAt | date: "dd/MM/yy" }}</td>
          <td>{{ item.puntos }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
