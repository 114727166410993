import { Component, OnInit, Input, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { ProveedoresInterface } from 'src/app/models/proveedores';
import { CategoriaInterface } from 'src/app/models/categorias';
import { Observable } from 'rxjs';
import { UsuarioInterface } from 'src/app/models/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { PuntosInterface } from 'src/app/models/puntos';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.scss']
})
export class ProveedoresComponent implements OnInit {
  /** Variable para verificar cuando se loguee el usuario */
  usuarioLogueado = false;
  /** Lista proveedores */
  public proveedores: ProveedoresInterface[] = [];
  /** Lista categorias */
  public categorias: CategoriaInterface[] = [];
  /** verifica carga */
  cargaCompleta = false;
  /** Variable para determinar carga inicial del modulo */
  cargado = false;
  /** Categoria seleccionado para mostrar detalle */
  categoriaSeleccionada: CategoriaInterface;

  /** Lista de proveedores por categoria para mostrar en detalle */
  listaSeleccionada: ProveedoresInterface[] = [];
  /** Lista de proveedores por categoria para mostrar en detalle */
  listaSeleccionadaDestacada: ProveedoresInterface[] = [];
  /** Lista de proveedores por categoria para mostrar en detalle */
  listaEncontradaDestacada: ProveedoresInterface[] = [];

  /** Lista de proveedores agrupada por categoria */
  listaAgrupada: any[] = [];
  /** Model para texo a buscar en categorias y proveedores */
  textoBuscar: string = '';
  /** categorias encontradas en la busqueda */
  categoriasEncontradas: CategoriaInterface[] = [];
  /** Proveedores encontrados en la busqueda */
  proveedoresEncontrados: ProveedoresInterface[] = [];
  /** Modelo para resetear busqueda */
  buscando: boolean = false;
  /** Bandera de control para desplegar modal de contacto del proveedor */
  modalContacto = false;
  /** Objeto usuario local storage*/
  public usuarioLocal: UsuarioInterface = null;
  /** modelo para el comentario */
  comentario: string = '';
  /** Objeto para control de datos del formulario de envio */
  public frmEnvio: FormGroup;

  /** Bandera para evaluar si se esta enviando el formulario de datos */
  public submited = false;

  /** Variable de control para el loading */
  loading: any;
  /** Objeto de datos del proveedor a contactar por el usuario */
  proveedorContactar: ProveedoresInterface;
  /** Permite abrir y cerrar el menu latelar en la vista responsive */
  public opened = true;
  /** Variable para saber cual categoria se selecciona */
  idCategoriaSeleccionada: string;
  /** Contador de selecciones */
  contadorSelecciones = 0;
  /** Modal de contactar */
  solicitar = false;
  /** Objeto de parametros fijos para el formulario de contacto */
  datosEnvio: any;
  /** Objeto de proveedores desde localStorage */
  storeProveedores: any;
  /** Objeto de categorias desde localStorage */
  storeCategorias: any;
  /** Variable de control para definir cuando se selecciona una categoria en la busqueda */
  selectCateBusqueda = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private title: Title, private meta: Meta,
    public mainService: MainService,
    public authService: AuthService,
    public fb: FormBuilder,
    public utilService: UtilService,
    public router: Router
  ) {
    this.usuarioLocal = this.authService.getUser();
    this.frmEnvio = this.fb.group({
      nit: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      comentario: ['', [Validators.required]]
    });
    this.authService.logueado.subscribe(logueado => {
      this.usuarioLogueado = logueado;
      if (logueado) {
        this.usuarioLocal = this.authService.getUser();
      }
    });
  }

  ngOnInit(): void {
    this.utilService.startLoading();
    if (isPlatformBrowser(this.platformId)) {
      this.storeProveedores = this.utilService.loadProveedores();
      if (this.storeProveedores !== false) {
        this.proveedores = JSON.parse(this.storeProveedores);
        this.listaAgrupada = _.chain(this.proveedores)
          .groupBy("categoria")
          .map((value, key) => ({ categoria: key, datos: value }))
          .value();
        this.getCategorias();
      }
    }
    this.getProveedores();
  }
  /**
   * Funcion para abreviar la obtencion de valores de campos del formulario
   */
  get f() {
    return this.frmEnvio.controls;
  }
  /**
   * Obtener lista de categorias registrados
   */
  getCategorias() {
    this.setSeo('YO FERRETERO :: La Aplicación Del Ferretero ::Proveedores', 'Aquí encontraras la guía de proveedores más completa del sector ferretero en un solo lugar', this.mainService.baseURL + 'assets/img/logo-login.png');
    if (isPlatformBrowser(this.platformId)) {
      this.storeCategorias = this.utilService.loadCategorias();
      if (this.storeCategorias !== false) {
        this.categorias = JSON.parse(this.storeCategorias);
        this.idCategoriaSeleccionada = this.categorias[0]._id;
        this.seleccionar({ id: this.idCategoriaSeleccionada, pos: 0 });
        let self = this;
        setTimeout(function () {
          if (!self.cargado) {
            self.cargado = true;
            self.cargaCompleta = true;
            self.utilService.stopLoading();
          }

        }, 100);
      }
    }
    this.mainService
      .get("api/categorias/nombre/1")
      .subscribe((data: CategoriaInterface[]) => {
        this.categorias = data;
        if (this.storeCategorias === JSON.stringify(data)) {

        } else {
          this.utilService.storeCategorias(data);

          this.idCategoriaSeleccionada = this.categorias[0]._id;
          this.seleccionar({ id: this.idCategoriaSeleccionada, pos: 0 });

          if (!this.cargado) {
            this.cargado = true;
            this.cargaCompleta = true;
            this.utilService.stopLoading();
          }
        }
      });
  }
  /**
   * Obtener lista de categorias registrados
   */
  getProveedores() {
    this.mainService.get("api/proveedores").subscribe((data: ProveedoresInterface[]) => {
      if (this.storeProveedores === JSON.stringify(data)) {

      } else {
        this.proveedores = data;
        this.utilService.storeProveedores(data);
        this.listaAgrupada = _.chain(data)
          .groupBy("categoria")
          .map((value, key) => ({ categoria: key, datos: value }))
          .value();
        this.getCategorias();
      }
    });
  }
  /**
   * Seleccion el categoria a mostrar el detalle
   * @param param0 Determina el item de la lista de categorias a seleccionar para mostrar
   */
  seleccionar({ id, pos }) {
    this.contadorSelecciones++;
    this.idCategoriaSeleccionada = id;
    let findCat = _.findIndex(this.categorias, { _id: id });
    this.categoriaSeleccionada = this.categorias[findCat];
    let find = _.findIndex(this.listaAgrupada, { categoria: id });
    this.listaSeleccionada = [];
    if (find !== -1) {
      this.listaSeleccionada = _.filter(this.listaAgrupada[find].datos, function (o) { return !o.destacado; });
      this.listaSeleccionada = _.orderBy(
        this.listaSeleccionada,
        ["nombre"],
        ["asc"]
      );
      this.listaSeleccionadaDestacada = _.filter(this.listaAgrupada[find].datos, function (o) { return o.destacado; });
      this.listaSeleccionada = _.orderBy(
        this.listaSeleccionada,
        ["nombre"],
        ["asc"]
      );
    }
    if (isPlatformBrowser(this.platformId)) {
      if (screen.width < 992) {
        this.toggleSidebar();
      }
    }
    if (this.contadorSelecciones === 2) {
      this.guardarVisita();
    }
    if (this.buscando == true) {
      this.selectCateBusqueda = true;
    } else {
      this.selectCateBusqueda = false;
    }
  }
  /**
   * Funcion para filtrar lista de proveedores y categorias
   */
  buscar() {
    if (this.textoBuscar === "") {
      this.categoriasEncontradas = [];
      this.proveedoresEncontrados = [];
      this.buscando = false;
    } else {
      const self = this;
      /** Proveedores encontrado */
      this.proveedoresEncontrados = _.filter(this.proveedores, function (obj) {
        return (
          obj.nombre
            .toLowerCase()
            .indexOf(self.textoBuscar.toLocaleLowerCase()) >= 0
        );
      });
      this.proveedoresEncontrados = _.uniq(
        this.proveedoresEncontrados,
        "nombre"
      );
      this.listaEncontradaDestacada = _.filter(this.proveedoresEncontrados, function (o) { return o.destacado; });
      this.listaEncontradaDestacada = _.orderBy(
        this.listaEncontradaDestacada,
        ["nombre"],
        ["asc"]
      );
      this.proveedoresEncontrados = _.filter(this.proveedoresEncontrados, function (o) { return !o.destacado; });
      this.proveedoresEncontrados = _.orderBy(
        this.proveedoresEncontrados,
        ["nombre"],
        ["asc"]
      );

      /** Categorias encontradas */
      this.categoriasEncontradas = _.filter(this.categorias, function (obj) {
        return (
          obj.nombre
            .toLowerCase()
            .indexOf(self.textoBuscar.toLocaleLowerCase()) >= 0
        );
      });
      this.buscando = true;
    }
  }
  resetearBusqueda() {
    this.textoBuscar = "";
    this.buscando = false;
    this.selectCateBusqueda = false;
  }
  /**
   * Funcion para enviar información de contacto al proveedor con copia a los administradores
   */
  onEnviar() {
    this.submited = true;
    if (this.frmEnvio.invalid) {
      return false;
    }
    let datos = this.frmEnvio.value;
    let email = this.utilService.adminEmail;
    if (this.proveedorContactar.email !== undefined && this.proveedorContactar.email !== null && this.proveedorContactar.email !== '') {
      email = this.proveedorContactar.email;
    }
    let mensaje = '<b>Nombre;</b>' + this.usuarioLocal.nombre + '<br><b>NIT:</b>' + datos.nit + '<br><b>Teléfono:</b>' + this.usuarioLocal.celular + '<br><b>Ciudad:</b>' + datos.ciudad + '<br><b>Comentario:</b>' + datos.comentario;
    mensaje += '<br><b>Proveedor:</b>' + this.proveedorContactar.nombre;
    let dataEmail = {
      //to: this.proveedor.email,
      to: email,
      from: this.usuarioLocal.email,
      subject: 'Contacto a proveedores - YoFerretero',
      contenido: mensaje,
      titulo: "Solicitud de contacto",
      cc: this.utilService.adminEmailCC
    };
    this.utilService.startLoading();
    this.mainService.sendEmail(dataEmail).subscribe((res: any) => {
      this.utilService.stopLoading();
      if (res.success) {
        this.utilService.showSuccess({ title: "¡Genial!", msj: 'Tu mensaje será enviado al proveedor y pronto se pondrán en contacto contigo.', time: 7000 });
        let puntuacion: PuntosInterface = {
          evento: "Contactar con proveedor",
          referencia: this.usuarioLocal._id,
          usuario: this.usuarioLocal._id,
          puntos: 30
        };
        this.utilService.guardarHistorialDePuntos(puntuacion);
      } else {
        this.utilService.showWarning({ title: "Alerta", msj: 'Ocurrio un error al enviar el correo, intentalo de nuevo.', time: 7000 });
      }
      this.frmEnvio.reset();
      this.comentario = '';
      this.modalContacto = false;
    });
  }
  onContactar(event) {
    if (this.usuarioLogueado) {
      this.solicitar = true;
      this.proveedorContactar = event;
      let email = this.utilService.adminEmailCC;
      if (this.proveedorContactar.email !== undefined && this.proveedorContactar.email !== null && this.proveedorContactar.email !== '') {
        email = this.proveedorContactar.email;
      }
      this.datosEnvio = {
        to: email,
        _id: this.proveedorContactar._id,
        subject: "Contacto a proveedores - YoFerretero",
        titulo: "Solicitud de contacto",
        evento: 'Solicitar cotización oferta',
        referencia: this.proveedorContactar._id
      };
    } else {
      this.utilService.showWarning({ title: 'Alerta', msj: 'Debe iniciar sesión para poder contactar con el proveedor', time: 5000 });
    }

  }
  /**
   * Emite que se envio la cotizacion
   * @param event Valor a emitir a pantalla padre
   */
  onEnviado(event) {
    this.solicitar = false;
  }
  /**
   * Metodo para guardar la visita del usuario
   */
  guardarVisita() {
    let user = "invitado";
    if (this.usuarioLogueado) {
      user = this.usuarioLocal._id;
    }
    let visita = {
      plataforma: 'web',
      usuario: user,
      modulo: 'proveedor',
      proveedor: this.categoriaSeleccionada._id
    }
    this.utilService.guardarVisita(visita);
  }
  /**
   * Mostrar y ocultar menu de categorias
   */
  toggleSidebar() {
    this.opened = !this.opened;
  }
  /**
   * Establece los patametros del SEO para la oferta
   */
  setSeo(title, description, image) {
    this.title.setTitle(title);
    this.meta.addTag({ property: 'og:title', content: '' }, true);
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:image:alt', content: title });
    this.meta.addTag({ property: 'og:image:alt', content: title });
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ name: 'title', content: title });
    this.meta.addTag({ itemprop: 'name', content: title });

    this.meta.addTag({ name: 'twitter:image', content: image });
    this.meta.addTag({ itemprop: 'image', content: image });
    this.meta.addTag({ property: 'og:image', content: image });

    this.meta.addTag({ name: 'twitter:description', content: description });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ itemprop: 'description', content: description });

    this.meta.addTag({ property: 'og:image', itemprop:"image",content: image });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ property: 'og:image:width', content: '300' });
    this.meta.addTag({ property: 'og:image:height', content: '200' });
    this.meta.addTag({ property: 'og:url', content: 'https://www.yoferretero.com/proveedores' });
  }
}
