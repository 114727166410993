<div *ngIf="proveedor.destacado">
  <div class="card card-destacado">
    <div class="row">
      <div class="col-5">
        <img
          [src]="proveedor.logo"
          *ngIf="
            proveedor.logo !== undefined &&
            proveedor.logo !== null &&
            proveedor.logo !== ''
          "
          class="img-fluid"
        />
        <img
          src="/assets/img/sin-foto-e.jpg"
          *ngIf="
            proveedor.logo == undefined ||
            proveedor.logo == null ||
            proveedor.logo == ''
          "
          class="img-fluid"
        />
      </div>
      <div class="col-7">
        <label class="texto-14">{{ proveedor.nombre }}</label>
        <label class="texto-13">{{ proveedor.direccion }}</label>
        <label class="texto-13" *ngIf="proveedor.email !== ''">{{
          proveedor.email
        }}</label>
        <label class="texto-13">{{ proveedor.telefono }}</label>
        <label class="texto-13">{{ proveedor.pagina }}</label>
        <label class="texto-13">{{ proveedor.ciudad }}</label>
      </div>
      <div class="col-12" *ngIf="usuarioLogueado" >
        <button type="button" (click)="onContactar()" class="boton-iniciar">
          Contactar
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!proveedor.destacado">
  <div class="row">
    <div class="col-12">
      <label class="texto-14">{{ proveedor.nombre }}</label>
      <label class="texto-13">{{ proveedor.direccion }}</label>
      <label class="texto-13" *ngIf="proveedor.email !== ''">{{
        proveedor.email
      }}</label>
      <label class="texto-13">{{ proveedor.telefono }}</label>
      <label class="texto-13">{{ proveedor.pagina }}</label>
      <label class="texto-13">{{ proveedor.ciudad }}</label>
    </div>
  </div>
</div>
