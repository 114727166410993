<div class="row">
  <div class="col">
    <div class="texto-titulo2">Comentarios</div>
  </div>
</div>
<div class="row" *ngIf="comentarios.length === 0">
  <div class="col">
    <div class="texto-info2">
      Se el primero en comentar
    </div>
  </div>
</div>
<div *ngIf="comentarios.length !== 0">
  <ul class="list-unstyled">
    <li class="media marginB-20" *ngFor="let item of comentarios">
      <picture *ngIf="item.usuario.foto != '' && item.usuario.foto != null && item.usuario.foto !== undefined"
        class="w-25">
        <source [srcset]="item.usuario.foto" type="image/svg+xml">
        <img [src]="item.usuario.foto" class="rounded-circle nuevo-avatar" alt="...">
      </picture>
      <picture *ngIf="item.usuario.foto == '' || item.usuario.foto == null || item.usuario.foto == undefined"
        class="w-25">
        <source srcset="/assets/img/sinfoto.png" type="image/svg+xml">
        <img src="/assets/img/sinfoto.png" class="rounded-circle nuevo-avatar" alt="...">
      </picture>
      <div class="media-body">
        <h6 class="texto-titulo">
          {{ item.usuario.nombre }}
        </h6>
        <p class="texto-info3" [innerHtml]="item.comentario"></p>
      </div>
    </li>
  </ul>
</div>