import { UtilService } from 'src/app/services/util.service';
import { Router } from '@angular/router';
import { OfertasInterface } from './../../../models/ofertas';
import { NoticiasInterface } from './../../../models/noticias';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsuarioInterface } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
import { YoaprendoInterface } from 'src/app/models/yoaprendo';

@Component({
  selector: 'app-favoritos',
  templateUrl: './favoritos.component.html',
  styleUrls: ['./favoritos.component.scss']
})
export class FavoritosComponent implements OnInit {
  /** Tab activo */
  tabActivo = 1;
  /** observable de noticias guardadas */
  @Input() public noticias: Observable<NoticiasInterface[]>;
  /** observable de ofertas guardadas */
  @Input() public ofertas: Observable<OfertasInterface[]>;
  /** observable de videos guardadas */
  @Input() public videos: Observable<YoaprendoInterface[]>;
  /** Lista de noticias guardadas */
  noticiasGuardadas: any[]=[];
  /** Lista de ofertas Guardadas  */
  ofertasGuardadas: any[]=[];
  /** Lista de videos Guardadas  */
  videosGuardados: any[]=[];
  /** Emisor cuando se quita una noticia */
  @Output() public actualizar = new EventEmitter<boolean>();
  /** Emisor cuando se actualiza una noticia */
  @Output() public actualizando = new EventEmitter<boolean>();
  /** usuario local storage */
  usuario: UsuarioInterface;
  usuarioLocal: UsuarioInterface;
  usuarioCargado=false;
  /** Emisor para detalle de OFerta */
  emisorUsuario = new BehaviorSubject({});
  constructor(public router: Router, public utilService: UtilService, public authService: AuthService, public mainService: MainService) {}

  ngOnInit(): void {
    this.emisorUsuario.next(null);
    this.usuarioLocal = this.authService.getUser();
    this.noticias.subscribe(data => {
      this.noticiasGuardadas = data;
      this.utilService.stopLoading();
    });
    this.ofertas.subscribe(data => {
      this.ofertasGuardadas = data;
    });
    this.videos.subscribe(data => {
      this.videosGuardados = data;
    });
   
    this.getUsuario();
  }
  /**
   * Seleccionar tab activo
   * @param param0 Tab a habilitar
   */
  setTab({ tab }) {
    this.tabActivo = tab;
  }
  /**
   * Ir a detalle de la oferta
   * @param id identificador de la oferta para ver el detalle
   */
  detalleOferta(id) {
    this.router.navigate(['/ferreofertas', id]);
  }
  /**
   * Actualiza la lista de noticias del usuario
   * @param even Verifica si se debe actualizar la informacion del usuario
   */
  onActualizarUsuario(even) {
    if (even) {
      this.actualizar.emit(true);
      this.getUsuario();
    }
  }
  /**
   *
   * @param event Verifica si debemos abrir el cargando
   */
  onModificando(event){
    this.actualizando.emit(true);
  }
  /**
   * ver detalle de la oferta
   */
  verDetalle({id}){
    this.router.navigate(['/detalle-oferta', id]);
  }
  /**
   * Obtiene el usuario activo para verificar si la noticia fue guardada o le dieron like
   */
  getUsuario() {
    this.mainService
      .get("api/user/" + this.usuarioLocal._id)
      .subscribe( (user: UsuarioInterface) => {
        this.usuario = user;
        this.emisorUsuario.next(user);
        this.usuarioCargado = true;
      });
  }
}
