import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'modal-recuperar-password',
  templateUrl: './modal-recuperar-password.component.html',
  styleUrls: ['./modal-recuperar-password.component.css']
})
export class ModalRecuperarPasswordComponent implements OnInit {

  /** Emisor para cerrar modal de olvidar y abrir el de login */
  @Output() public olvidar = new EventEmitter<boolean>();
  /** Formulario de control para el login de usuarios */
  public frmOlvide: FormGroup;
  /** Variable para controlar si se esta enviando la informacion del formulario */
  public submited = false;

  /** verificar si se carga la informacion */
  cargado: boolean = false;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public utilService: UtilService
  ) {
    this.frmOlvide = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
  }
  /**
   * Metodo para abreviar la obtencion de los campos del formulario en la vista html
   */
  get f() {
    return this.frmOlvide.controls;
  }

  /**
   * Metodo para la verificacion del login del usuario
   */
  async onEnviar() {
    this.submited = true;
    if (this.frmOlvide.invalid) {
      return false;
    }
    this.utilService.startLoading();
    const olvide = this.frmOlvide.value;
    this.authService.forgot(olvide).subscribe((resp: any) => {

        this.utilService.showWarning({
          title: "Alerta",
          msj: resp.msg,
          time: 5000
        });
        this.utilService.stopLoading();
        this.frmOlvide.reset();


      },
      error => {
        this.frmOlvide.reset();
        this.utilService.stopLoading();
        console.log(error);
        alert("Ocurrio un error.");
      }
    );
  }

  /**
   * Metodo para salir del modal de login
   */
  salir(){
    this.olvidar.emit(false);
  }

}
