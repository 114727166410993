import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { UtilService } from "../../../services/util.service";
import { AuthService } from "../../../services/auth.service";
import { MainService } from 'src/app/services/main.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
declare const $: any;
declare interface RouteInfo {
  value: string;
  titulo: string;
  url: string;
}
export const ROUTES: RouteInfo[] = [
  { value: "1", titulo: "Actualidad", url: "/actualidad" },
  // { value: "2", titulo: "Ferreofertas", url: "/ferreofertas" },
  { value: "2", titulo: "Proveedores", url: "/proveedores" },
  { value: "3", titulo: "Juego", url: "/juego" },
  // { value: "5", titulo: "Horóscopo", url: "/horoscopo" },
];
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  /** Verifica si el usuario esta logueado */
  usuarioLogueado = false;
  /** Verifica si se esta enviando el email */
  enviando = false;
  /** Almacena los items del menu */
  menuItems: any[];
  /** fecha actual */
  hoy: Date;
  /** email */
  email: string = '';
  /** Bandera para evaluar si se esta enviando el formulario de datos */
  public submited = false;
  /** variable de control para mostrar y ocultar modal de login */
  modalLogin: boolean = false;
  /** variable de control para mostrar y ocultar modal de registro */
  modalCrear: boolean = false;
  constructor(public utilService: UtilService,
    public mainService: MainService,
    public authService: AuthService,
    @Inject(PLATFORM_ID) private platformId) {
    this.hoy = new Date();
    this.authService.logueado.subscribe(logueado => {
      this.usuarioLogueado = logueado;
    });
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  /**
   * Metodo para ocultar menu de navegacion en vista responsive
   */
  toggleNavbar() {
    if (isPlatformBrowser(this.platformId)) {
      let element: HTMLElement = document.getElementById('botonMenu') as HTMLElement;
      element.click();
    }
  }
  /**
   * Ocultar login desde el componente modal-login
   * @param event disparador del evento
   */
  onCerrarLogin(event) {
    this.modalLogin = event;
  }
  /**
   * Mostrar modal de registro de usuario con disparador desde el modal de login de usuario
   * @param event Disparador del evento
   */
  onRegistroLogin(event) {
    this.modalLogin = false;
    this.modalCrear = true;
  }
  /**
   * Ocultar login desde el componente modal-login
   * @param event disparador del evento
   */
  onCerrarCrear(event) {
    this.modalCrear = event;
  }

  /**
   * Funcion para enviar cotización
   */
  onEnviar() {
    this.submited = true;
    let fromField = this.email + ""
    let mensaje = "Solicitud de subscripción del email " + this.email
    let dataEmail = {
      to: this.utilService.adminEmail,
      from: fromField,
      subject: "Solicitud de subscripción",
      contenido: mensaje,
      titulo: "Solicitud de subscripción",
      cc: this.utilService.adminEmailCC
    };
    this.enviando = true;
    this.utilService.startLoading();
    this.mainService.sendEmail(dataEmail).subscribe((res: any) => {
      if (res.success) {
        this.utilService.showSuccess({ title: "¡Genial!", msj: 'Tu mensaje fue enviado, pronto se pondrán en contacto contigo.', time: 5000 })
        this.email = ""
      } else {
        this.utilService.showWarning({ title: "Alerta", msj: 'Ocurrio un error al enviar el correo, intentalo de nuevo.', time: 5000 })
        this.email = ""
      }
      this.enviando = false;
      this.utilService.stopLoading();
      this.submited = false;
    });
  }

  /**
   * Metodo para abrir enlace externos
   */
  enlaceExterno(url) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(url, '_blank');
    }
  }
}
