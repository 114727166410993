import { UtilService } from 'src/app/services/util.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { UsuarioInterface } from '../../../models/user';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { PuntosInterface } from 'src/app/models/puntos';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'tarjeta-noticia-contenido',
  templateUrl: './tarjeta-noticia-contenido.component.html',
  styleUrls: ['./tarjeta-noticia-contenido.component.scss']
})
export class TarjetaNoticiaContenidoComponent implements OnInit {
  /** Variable para verificar si el usuario esta logueado */
  usuarioLogueado = false;
  /** Emisor para actualizar datos del usuario */
  @Output() public modificado = new EventEmitter<boolean>();
  /** Datos de la noticia a mostrar */
  @Input() item;
  /** usuario activo */
  usuario: UsuarioInterface;
  @Input() public usuarioOb: Observable<any>;
  /** usuario activo */
  usuarioLocal: UsuarioInterface;
  /** Bandera para verificar si la noticia fue guardada por el usuario */
  save: boolean = false;
  /** Bandera para verificar si la noticia tiene like por parte del usuario*/
  like: boolean = false;
  /** Puntos cuando se guarda */
  puntosGuardar: number = 10;
  /** Puntos cuando se da like */
  puntosLike: number = 5;
  /** Bandera del cargando cuando se esta modificando la tarjeta */
  @Output() public modificando = new EventEmitter<boolean>();
  /** variable de control para mostrar y ocultar modal de compartir */
  modalCompartir: boolean = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    public utilService: UtilService,
    public mainService: MainService,
    public authService: AuthService
  ) {
    this.usuarioLocal = this.authService.getUser();
    this.authService.logueado.subscribe(logueado => {
      this.usuarioLogueado = logueado;
      if (logueado) {
        this.usuarioLocal = this.authService.getUser();
        this.getUsuario();
      }
    });
  }

  ngOnInit() {
    this.usuarioOb.subscribe(data => {
      this.usuario = data;
      if (this.usuarioLogueado) {
        this.getUsuario();
      }
    });
  }
  /**
   * Obtiene el usuario activo para verificar si la noticia fue guardada o le dieron like
   */
  getUsuario() {
    if (this.usuario !== undefined && this.usuario !== null ){
      if (
        this.usuario.noticiasGuardadas === undefined ||
        this.usuario.noticiasGuardadas === null
      ) {
        this.usuario.noticiasGuardadas = [];
      }
      if (
        this.usuario.ofertasGuardadas === undefined ||
        this.usuario.ofertasGuardadas === null
      ) {
        this.usuario.ofertasGuardadas = [];
      }
      if (
        this.usuario.noticiasLike === undefined ||
        this.usuario.noticiasLike === null
      ) {
        this.usuario.noticiasLike = [];
      }
      if (
        this.usuario.ofertasLike === undefined ||
        this.usuario.ofertasLike === null
      ) {
        this.usuario.ofertasLike = [];
      }
      let findSave = this.usuario.noticiasGuardadas.indexOf(this.item._id);
      if (findSave !== -1) {
        this.save = true;
      }
      let findLike = this.usuario.noticiasLike.indexOf(this.item._id);
      if (findLike !== -1) {
        this.like = true;
      }
    }

  }

  /**
   * Redirije a la vista de detalle de noticia
   * @param param0  indica el id de la noticia a mostrar
   */
  onDetalleNoticia({ id }) {
    this.router.navigate(['/noticias', id]);
  }
  /**
   * Metodo para agregar y quitar noticia de los guardados del usuario
   */
  toogleSave() {
    if (!this.usuarioLogueado) {
      this.utilService.showWarning({
        time: 5000,
        title: 'Alerta',
        msj: 'Inicia sesión para guardar la noticia!'
      });
      return false;
    }
    if (this.save) {
      let findSave = this.usuario.noticiasGuardadas.indexOf(this.item._id);
      this.usuario.noticiasGuardadas.splice(findSave, 1);
      this.usuario.puntos = this.usuario.puntos - this.puntosGuardar;
      this.quitarFavorito();
    } else {
      this.usuario.noticiasGuardadas.push(this.item._id);
      this.usuario.puntos = this.usuario.puntos + this.puntosGuardar;
      this.guardarFavorito();
    }
    this.save = !this.save;
  }
  /**
   * Metodo para agregar al modelo de noticias guardadas por  el usuario
   */
  guardarFavorito() {
    let data = {
      tipo: 'noticia',
      usuario: this.usuario._id,
      noticia: this.item._id
    };
    this.mainService.post('api/saved', data).subscribe(result => {
      let puntuacion: PuntosInterface = {
        evento: 'Agregar noticia a favoritos',
        referencia: this.item._id,
        usuario: this.usuario._id,
        puntos: this.puntosGuardar
      };
      this.utilService.guardarHistorialDePuntos(puntuacion);
      this.modificarUsuario('save');
    });
  }
  /**
   * Metodo para quitar al modelo de noticias guardadas por  el usuario
   */
  quitarFavorito() {
    this.mainService
      .delete('api/savedNoticia/' + this.usuario._id + '/' + this.item._id)
      .subscribe(result => {
        let puntuacion: PuntosInterface = {
          evento: 'Quitar noticia de favoritos',
          referencia: this.item._id,
          usuario: this.usuario._id,
          puntos: this.puntosGuardar * -1
        };
        this.utilService.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('save');
      });
  }
  /**
   * Metodo para agregar y quitar like de la noticia por el usuario
   */
  toogleLike() {
    if (!this.usuarioLogueado) {
      this.utilService.showWarning({
        time: 5000,
        title: 'Alerta',
        msj: 'Inicia sesión para dar like en la noticia!'
      });
      return false;
    }
    if (this.like) {
      let findLike = this.usuario.noticiasLike.indexOf(this.item._id);
      this.usuario.noticiasLike.splice(findLike, 1);
      this.usuario.puntos = this.usuario.puntos - this.puntosLike;
      this.quitarLike();
    } else {
      this.usuario.noticiasLike.push(this.item._id);
      this.usuario.puntos = this.usuario.puntos + this.puntosLike;
      this.guardarLike();
    }
    this.like = !this.like;
  }
  /**
   * Metodo para agregar al modelo de noticias guardadas por  el usuario
   */
  guardarLike() {
    let likes = 0;
    if (this.item.likes !== undefined && this.item.likes !== null) {
      likes = this.item.likes;
    }
    likes++;
    let noticiaUpdate = {
      likes: likes
    };
    this.mainService
      .put('api/noticia/' + this.item._id, noticiaUpdate)
      .subscribe(result => {
        let puntuacion: PuntosInterface = {
          evento: 'Like en noticia',
          referencia: this.item._id,
          usuario: this.usuario._id,
          puntos: this.puntosLike
        };
        this.utilService.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('like');
        this.item.likes++;
      });
  }
  /**
   * Metodo para quitar like de noticias y del usuario
   */
  quitarLike() {
    let likes = 0;
    if (this.item.likes !== undefined && this.item.likes !== null) {
      likes = this.item.likes;
    }
    likes--;
    let noticiaUpdate = {
      likes: likes
    };
    this.mainService
      .put('api/noticia/' + this.item._id, noticiaUpdate)
      .subscribe(result => {
        let puntuacion: PuntosInterface = {
          evento: 'Quitar Like en noticia',
          referencia: this.item._id,
          usuario: this.usuario._id,
          puntos: this.puntosLike * -1
        };
        this.utilService.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('like');
        this.item.likes--;
      });
  }
  /**
   * Modificar datos del usuario
   */
  modificarUsuario(tipo) {
    this.mainService
      .put('api/user/' + this.usuario._id, this.usuario)
      .subscribe((result: any) => {
        if (result.success) {
          this.updateStorage();
        }
      });
  }
  /**
   * Actualizar datos del local storage de usuario
   */
  updateStorage() {
    let token = this.authService.getToken();
    this.authService.storeUserData(token, this.usuario);
    this.modificado.emit(true);
  }
  /**
   * Genera enlace para compartir en redes sociales
   */
  enlaceShared() {
    return  this.mainService.baseURLssr +'noticias/' + this.item.slug;
  }
  obtenerParrafo(){
    if (isPlatformBrowser(this.platformId)) {
    let cont = document.getElementById(this.item._id);
    //console.log(cont.firstChild.textContent);
    return cont.firstChild.textContent.substring(0,100) + "...";
    }
  }
   /**
   * Evento al compartir
   */
  compartiendo(){
    let puntuacion: PuntosInterface = {
      evento: "Compartir noticia",
      referencia: this.item._id,
      usuario: this.usuario._id,
      puntos: 5
    };
    this.utilService.guardarHistorialDePuntos(puntuacion);
  }
}
