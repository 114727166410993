<nav class="site-header sticky-top py-1 head navbar d-lg-none fondo-custom-header-resp ">
  <button class="navbar-toggler boton-menu-responsive" type="button" data-toggle="collapse"
    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
    aria-label="Toggle navigation" id="botonMenu">
    <i class="fa fa-bars"></i>
  </button>
  <img class="logo-header" src="/assets/img/logo-horizontal-blanco.png" />
  <div class="collapse navbar-collapse mt-20" id="navbarSupportedContent">
    <div class="texto-menu container d-flex flex-column flex-lg-row">
      <a class="py-2 d-none d-inline-block my-auto" routerLinkActive="active-link" *ngFor="let menuItem of menuItems"
        [routerLink]="[menuItem.url]" (click)="toggleNavbar()">{{ menuItem.titulo }}</a>
      <a *ngIf="usuarioLogueado" class="py-2 d-none d-inline-block  my-auto" routerLinkActive="active-link"
        [routerLink]="['/perfil']" (click)="toggleNavbar()">Mi perfil</a>
      <a *ngIf="!usuarioLogueado" class="py-2 d-none d-inline-block  my-auto"
        (click)="modalLogin = true; toggleNavbar()">Ingresa</a>
      <a *ngIf="!usuarioLogueado" class="py-2 d-none d-inline-block  my-auto"
        (click)="modalCrear = true; toggleNavbar()">Registrate</a>
    </div>
  </div>
</nav>

<div class="d-none d-lg-block  upper-header ">
  <div class="container mx-auto h-100">
    <div class="row h-100 titulo-container ">
      <div class="col my-auto">
        <label class="mb-0">
          <i class="fa fa-calendar calendario"></i>
        </label>
        <label class="text-white mb-0">{{
          hoy
          }}</label>
        <label class="text-white marginL-20 mb-0" (click)="modalContacto = true;">Contacto</label>
        <label class="text-white marginL-20 mb-0" (click)="modalCrear = true" *ngIf="!usuarioLogueado">Registro</label>
        <div class="ali-derecha">
          <i (click)="enlaceExterno('https://www.facebook.com/Yo-Ferretero-111701770647943')"
            class="fa fa-facebook marginL-20 icono-extra"></i>
          <i (click)="enlaceExterno('https://www.instagram.com/yoferretero/?hl=en')"
            class="fa fa-instagram marginL-20 icono-extra"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="d-none d-lg-block">
  <div class="title-news">
    <div class="container mx-auto paddingL-0">
      <div class="col-12 my-auto headerBanner">
        <img class="logo-header " src="/assets/img/logo-header.png" />
        <div class="logo-header2 max-height-add">

          <div class="row" *ngIf="cargado">

            <div class="col-12 col-sm-10 max-height-add">
              <div class="div-premio max-height-add">
                <div class="row max-height-add">
                  <div class="col-12">
                    <h3 class="titulo-banner">{{ premio.titulo }}</h3>
                  </div>
                  <div class="col-12 col-sm-8">
                    <p class="texto-banner">{{ premio.texto }}</p>
                  </div>
                  <div class="col-12 col-sm-4">
                    <img [src]="premio.logo.url" class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-2 fondo-premio" [ngStyle]="{ background: 'url(' + premio.imagen.url + ')' }">

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->

<div class="row new-sub-header" *ngIf="premio">
  <div class="col-left">
    <img class="logo " src="/assets/img/logo-header.png" />
  </div>
  <div class="col-right">
    <div class="wrapper">
      <!-- Premio -->
      <div class="reward">
        <img [src]="premio.imagen.url" />
      </div>
    </div>
  </div>
</div>

<div class="d-none d-lg-block  fondo-custom-header">
  <div class="container mx-auto h-100 paddingL-0 paddingR-0">

    <nav class=" nav navbar-nav navbar-dark navbar-expand-lg h-100 w-100 nav-custom">

      <div class="container-fluid my-auto  ">
        <div>
          <ul class="navbar  nav my-auto paddingL-0 paddingR-0" routerLinkActive="active">
            <li routerLinkActive="active-link" *ngFor="let menuItem of menuItems" class="nav-item my-auto ">
              <a class="nav-link menu-item my-auto my-auto" [routerLink]="[menuItem.url]">
                <p class="menu-item my-auto ">{{ menuItem.titulo }}</p>
              </a>
            </li>
            <li *ngIf="usuarioLogueado" routerLinkActive="active-link" class="nav-item my-auto ">
              <a class="nav-link menu-item my-auto my-auto" [routerLink]="['/perfil']">
                <p class="menu-item my-auto ">Mi perfil</p>
              </a>
            </li>
            <li class="  no-padding" *ngIf="!usuarioLogueado">
              <button type="button" class="btn btn-light" (click)="modalLogin = true">
                <p class="button-text-orange my-auto">Ingresa</p>
              </button>
            </li>
            <li class="  no-padding" *ngIf="!usuarioLogueado">
              <button type="button" class="btn btn-outline-light" (click)="modalCrear = true">
                <p class="button-text-white my-auto">Registrate</p>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>
<modal-login [hidden]="!modalLogin" (cerrar)="onCerrarLogin($event)" (registro)="onRegistroLogin($event)"
  (olvidar)="onLoginOlvidar($event)"></modal-login>
<modal-crear [hidden]="!modalCrear" (cerrar)="onCerrarCrear($event)"></modal-crear>
<modal-contacto-home [param]="datosEnvio" [hidden]="!modalContacto" (enviadoCerrar)="onCerrarContacto($event)">
</modal-contacto-home>
<modal-recuperar-password [hidden]="!modalOlvidar" (olvidar)="onCerrarOlvidar($event)"></modal-recuperar-password>
