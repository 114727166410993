<div class="container mx-auto marginT-20" [hidden]="detalle === true">
  <div class="row">
    <div class="col">
      <h3>Horóscopo</h3>
    </div>
  </div>

  <div class="row">
    <div
      class="col-12 col-sm-6 col-md-4 col-lg-3"
      *ngFor="let item of listaSignos; let i = index"
      (click)="seleccionar({ pos: i })"
    >
      <tarjeta-horoscopo [item]="item"></tarjeta-horoscopo>
    </div>
  </div>
</div>

<div class="container mx-auto marginT-20" [hidden]="detalle === false">
  <div class="row" (click)="onVolver()">
    <div class="col">
      <div class="texto-info">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        Volver al horóscopo
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <horoscopo-detalle
        *ngIf="detalle"
        [item]="hSeleccionada"
      ></horoscopo-detalle>
    </div>
  </div>
</div>
<div [hidden]="cargado">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="square-jelly-box"
    [fullScreen]="true"
    ><p class="texto-blanco">Cargando...</p></ngx-spinner
  >
</div>
