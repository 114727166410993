import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { UsuarioInterface } from 'src/app/models/user';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  /** Verifica si el usuario esta logueado */
  usuarioLogueado = false;
  /** Usuario desde localStorage */
  usuarioLocal: UsuarioInterface;
  /** usuario activo */
  usuario: UsuarioInterface;
  /** Variable de control para determinar que los datos fueron cargados */
  cargaCompleta = false;
  /** Determina tab activo */
  panelActivo = 1;
  /** Permite abrir y cerrar el menu latelar en la vista responsive */
  public opened = true;
  /** Puntos del mes */
  puntosMes: number;
  /** Emisor para actualizarUsuario */
  emisorUsuario = new BehaviorSubject({});
  /** Emisor para actualizarUsuario */
  emisorPuntos = new BehaviorSubject(0);
  /** Emisor para noticias */
  emisorNoticias = new BehaviorSubject([]);
  /** Emisor para ofertas */
  emisorOfertas = new BehaviorSubject([]);
  /** Emisor para videos */
  emisorVideos = new BehaviorSubject([]);
  /** Lista noticias guardadas */
  noticiasGuardadas: any[];
  /** Lista de ofertas guardadas */
  ofertasGuardadas: any[];
  /** Lista de videos guardados */
  videosGuardados: any[];
  /** Variable de control para mostrar y ocultar modal de puntos */
  modalPuntos = false;
  /** Variable de contron para modal de cambiar clave */
  cambiandoClave = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public mainService: MainService,
    public authService: AuthService,
    public router: Router,
    private rutaActiva: ActivatedRoute,
    public utilService: UtilService
  ) {
    this.usuarioLocal = this.authService.getUser();
    this.authService.logueado.subscribe(logueado => {
      this.usuarioLogueado = logueado;
      if (logueado) {
        this.usuarioLocal = this.authService.getUser();
      }
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (screen.width < 992) {
        this.opened = false;
      }
    }
    this.utilService.startLoading();
    if (this.rutaActiva.snapshot.params.tab !== undefined) {
      this.panelActivo = parseInt(this.rutaActiva.snapshot.params.tab);
    }
    this.getUsuario();
  }
  /**
   * Obtiene los datos del usuario desde la base de datos
   */
  getUsuario() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/user/' + this.usuarioLocal._id)
        .subscribe(user => {
          this.usuario = user;
          if (
            this.usuario.foto === undefined ||
            this.usuario.foto === null ||
            this.usuario.foto === '' ||
            this.usuario.foto === '/assets/admin/sinfoto.png'
          ) {
            this.usuario.foto = '/assets/img/ferretero.png';
          }
          this.emisorUsuario.next(this.usuario);
          this.getNoticiasUsuario();
        });
    }
  }
  /**
   * Obtiene noticias guardadas por el usuario
   */
  getNoticiasUsuario() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/savedUser/' + this.usuario._id + '/noticia')
        .subscribe((result: any) => {
          if (result.success) {
            let data = [];
            for (let not of result.data) {
              if (not.noticia != null) {
                data.push(not);
              }
            }

            this.noticiasGuardadas = data;
          } else {
            this.noticiasGuardadas = [];
          }
          this.emisorNoticias.next(this.noticiasGuardadas);
          this.getOfertas();
        });
    }
  }
  /**
   * Obtiene las ofertas guardadas por el usuario
   */
  getOfertas() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/savedUser/' + this.usuario._id + '/oferta')
        .subscribe((result: any) => {
          if (result.success) {
            let data = [];
            for (let ofer of result.data) {
              if (ofer.oferta != null) {
                data.push(ofer);
              }
            }
            this.ofertasGuardadas = data;
          } else {
            this.ofertasGuardadas = [];
          }
          this.emisorOfertas.next(this.ofertasGuardadas);
          //this.emisorVideos.next(this.videosGuardados);
          //this.cargaCompleta = true;
          this.getVideos();
        });
    }
  }
  /**
   * Obtiene los videos guardados por el usuario
   */
   getVideos() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/savedUser/' + this.usuario._id + '/yoAprendo')
        .subscribe((result: any) => {
          if (result.success) {
            let data = [];
            for (let ofer of result.data) {
              if (ofer.video != null) {
                data.push(ofer);
              }
            }
            this.videosGuardados = data;
          } else {
            this.videosGuardados = [];
          }
          this.emisorVideos.next(this.videosGuardados);
          this.cargaCompleta = true;
          this.utilService.stopLoading();
        });
    }
  }
  /**
   * Cerrar sesion de usuario
   */
  onLogout() {
    if (this.authService.logout()) {
      this.router.navigate(['/']);
    }
  }
  /**
   * Mostrar y ocultar menu lateral
   */
  private toggleSidebar() {
    this.opened = !this.opened;
  }
  /**
   *
   * @param even Verifica si se debe actualizar la informacion del usuario
   */
  onActualizarUsuario(even) {
    if (isPlatformBrowser(this.platformId)) {
      if (even) {
        this.mainService
          .get('api/user/' + this.usuarioLocal._id)
          .subscribe(user => {
            this.usuario = user;
            if (
              this.usuario.foto === undefined ||
              this.usuario.foto === null ||
              this.usuario.foto === ''
            ) {
              this.usuario.foto = '/assets/img/ferretero.png';
            }
            this.emisorUsuario.next(this.usuario);
            this.updateStorage();
            this.getNoticiasUsuario();
          });
      }
    }
  }
  /**
   * Habilita el cargando cuando se hacen ajustes en el modulo de noticias guardadas
   * @param event Emisor
   */
  onActualizando(event) {
    this.utilService.startLoading();
  }
  /**
   * Actualizar datos del local storage de usuario
   */
  updateStorage() {
    if (isPlatformBrowser(this.platformId)) {
      let token = this.authService.getToken();
      this.authService.storeUserData(token, this.usuario);
    }
  }

  /** Obtener puntos del mes */
  onPuntosMes(event) {
    this.puntosMes = event;
    this.emisorPuntos.next(this.puntosMes);
  }
  seleccionarTab(tab) {
    if (isPlatformBrowser(this.platformId)) {
      this.panelActivo = tab;
      if (tab === 2) {
        this.modalPuntos = true;
      }
      if (screen.width < 992) {
        this.toggleSidebar();
      }
    }
  }
  /**
   * Cierra o abre modal de cambio de clave
   * @param event respuesta del evento de cerrar ventana de modal de cambiar clave
   */
  onCambioDeClave(event) {
    this.cambiandoClave = event;
  }
}
