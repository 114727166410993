import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tarjeta-oferta',
  templateUrl: './tarjeta-oferta.component.html',
  styleUrls: ['./tarjeta-oferta.component.css']
})
export class TarjetaOfertaComponent implements OnInit {
  /** Datos de la oferta */
  @Input() item;
  constructor() { }

  ngOnInit(): void {

  }

}
