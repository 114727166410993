import { Component, OnInit } from '@angular/core';
import { PremioInterface } from 'src/app/models/premio';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {
  /** Ranking de usuarios */
  ranking: any[];
  /** Controla carga de datos */
  cargado = false;
  /** Datos de premio del mes desde base de datos */
  premio: PremioInterface;
  constructor(public mainService: MainService) { }
  /** Modelo de anio para generar lista de puntuacion por mes */
  anio: number;
  /** Modelo de mes para generar lista de puntuacion por mes */
  mes: number;
  ngOnInit(): void {
    let fecha = new Date();
    this.anio = fecha.getFullYear();
    this.mes = fecha.getMonth()+1;
    this.getRanking();
  }
  /**
   * Obtiene lista de los 10 usuarios con mas puntos
   */
  getRanking(){
    this.mainService.get('api/puntosMes').subscribe( (rank: any) => {
      if (rank.success){
        this.ranking = rank.data;
      }
      this.getPremio();
    });
  }
  /**
   * Obtiene lista de los 10 usuarios con mas puntos
   */
  getRankingMes(){
    this.mainService.get('api/puntosMes/'+this.anio+'/'+this.mes).subscribe( (rank: any) => {
      if (rank.success){
        this.ranking = rank.data;
      }
      this.getPremio();
    });
  }
  /**
   * Obtener datos del premio del mes
   */
  getPremio() {
    this.mainService.get('api/premio').subscribe( (premio: PremioInterface[]) => {
      this.premio= premio[0];
      this.cargado = true;
    });
  }

}
