import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'datediff'
})
export class DatediffPipe implements PipeTransform {

  transform(value: any, ...args: any): any {
    let actual = moment();
    let sufijo = 'd';
    let difference = actual.diff(moment(value),'days');
    if (difference === 0) {
      sufijo = 'h';
      difference = actual.diff(moment(value),'hours');
    }
    if (difference === 0) {
      sufijo = 'm';
      difference = actual.diff(moment(value),'minutes');
    }
    return difference + sufijo;
  }

}
