import { Inject, Injectable, Injector, Optional, PLATFORM_ID } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(@Inject(PLATFORM_ID) private platformId,
        @Optional() @Inject('REQUEST') private request: Request,
        private cookieService: CookieService) {
    }

    /**
     * Intercepts the http requests and adds the authorization token
     * @param req Http request
     * @param next Http handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // If the app is currently running on the server
        if (isPlatformServer(this.platformId)) {
            // Get the cookie from the request header
            const cookie = this.request.headers['cookie'];
            if (cookie !== undefined && cookie !== null && cookie !== '') {
                // If the cookie exists and has a token, add it to the authorization headers
                const token = this.getItem(cookie, 'tokenYFweb');
                if (token !== null) {
                    req = req.clone({
                        headers: req.headers.set('Authorization', token)
                    });
                }
            }
        } else if (isPlatformBrowser(this.platformId)) {
            // If the app is currently running on the browser
            const token = this.cookieService.get('tokenYFweb');
            // Add the token to the headers by retrieving it from the cookie service
            if (token !== null && token !== undefined && token !== '') {
                req = req.clone({
                    headers: req.headers.set('Authorization', token),
                });
            }
        }
        return next.handle(req);
    }

    getItem(cookies, sKey): string {
        if (!sKey) {
            return null;
        }
        return decodeURIComponent(cookies.replace(new RegExp(
            '(?:(?:^|.*;)\\s*' +
            encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
            '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1'
        )
        ) || null;
    }
}