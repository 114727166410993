import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProveedoresInterface } from 'src/app/models/proveedores';

@Component({
  selector: 'tarjeta-proveedor',
  templateUrl: './tarjeta-proveedor.component.html',
  styleUrls: ['./tarjeta-proveedor.component.scss']
})
export class TarjetaProveedorComponent implements OnInit {
  /** Verifica si el usuario esta logueado */
  usuarioLogueado = false;
  /** Datos del proveedor */
  @Input() proveedor;
  /** Emisor para actualizar menu lateral */
  @Output() public contactar = new EventEmitter<ProveedoresInterface>();
  constructor(public authService: AuthService) {
    this.authService.logueado.subscribe(logueado => {
      this.usuarioLogueado = logueado;
    });
  }

  ngOnInit(): void {
  }
  /**
   * Emite evento para mostrar modal de contacto para el proveedor seleccionado
   */
  onContactar() {
    this.contactar.emit(this.proveedor);
  }

}
