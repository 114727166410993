<div class="contenedor-tarjeta">
  <a class="w-100" [routerLink]="['/video', item.slug]">
    <div class="row">
      <div class="col">
        <div class="image-container">
          <div class="icono-signo my-auto" [ngStyle]="{ 'background-image': 'url(' + item.imagen + ')' }"></div>
        </div>

        <div class="contenido">
          <div class="row">
            <div class="col-12">
              <div class="texto-titulo">
                {{ item.titulo }}
                <fa-icon class="position-date" [icon]="faArrowRight"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
</div>
