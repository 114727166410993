import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UsuarioInterface } from 'src/app/models/user';
import { YoaprendoInterface } from 'src/app/models/yoaprendo';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-yoaprendo',
  templateUrl: './yoaprendo.component.html',
  styleUrls: ['./yoaprendo.component.css']
})
export class YoaprendoComponent implements OnInit {
  /** Verifica si el usuario esta logueado */
  usuarioLogueado = false;
  /** Variable de control para determinar que los datos fueron cargados */
  cargaCompleta = false;
  /** Objeto de videis desde localStorage */
  storeVideos: any;
   /** usuario activo */
   usuario: UsuarioInterface;
   /** usuario local storage */
   usuarioLocal: UsuarioInterface;
   usuarioCargado=false;
   /** Emisor para detalle de OFerta */
  emisorUsuario = new BehaviorSubject({});
  /** Arreglo que almacena la lista de videos */
  listaVideos: YoaprendoInterface[] = [];
  /** Cantidad de Videos */
  totalVideos = 0;
  /** Objeto donde se alamacenara el video principal */
  videoPrincipal: any;
  /** Mostrar ver mas */
  mostrarVerMas = true;
  /** Contadores de ver mas */
  contVermas = 1;
  /** variable de control para cantidad de videos a mostrar */
  cantVideos = 10;
  constructor(@Inject(PLATFORM_ID) private platformId,
  private title: Title, private meta: Meta,
  public mainService: MainService,
  public authService: AuthService,
  public router: Router,
  public utilService: UtilService) { 
    this.emisorUsuario.next(null);
    this.usuarioLocal = this.authService.getUser();
    this.authService.logueado.subscribe( logueado => {
      this.usuarioLogueado =  logueado;
      if (logueado) {
        this.usuarioLocal = this.authService.getUser();
        this.getUsuario();
      } else {
        this.usuarioCargado = true;
      }
    });
  }

  ngOnInit(): void {
    this.setSeo();
    this.utilService.startLoading();

    if (isPlatformBrowser(this.platformId)) {
      this.storeVideos = this.utilService.loadVideos();
      if (this.storeVideos !== false) {
        this.listaVideos = JSON.parse(this.storeVideos);
        let principal = this.listaVideos.splice(0, 1);
        this.totalVideos = this.listaVideos.length;
        this.videoPrincipal = principal[0];
        let self = this;
        setTimeout(function () {
          self.cargaCompleta = true;
          self.utilService.stopLoading();
        }, 100);
      }
      this.getVideos();
    }
  }
  /**
   * Obtiene lista de videos publicados
   */
   getVideos() {
    let now = new Date();
    this.mainService.get("api/yoaprendoPublicadas/" + now).subscribe(async (videos: any) => {
      videos = await this.formatVideos(videos)
      if (this.storeVideos === JSON.stringify(videos)) {
        console.log("Son iguales");
      } else {
        this.listaVideos = videos;
        this.utilService.storeVideos(this.listaVideos);
        //let principal = this.listaVideos.splice(0, 1);
        this.totalVideos = this.listaVideos.length;
        //this.videoPrincipal = principal[0];
        this.cargaCompleta = true;
        this.utilService.stopLoading();
      }
      
    });
  }
  public formatVideos(videos) {
    return new Promise(success => {
      videos.forEach(async element => {
        element.imagen = await this.getThumb(element.video);
        if (element.imagen && element.imagen.indexOf("https") == -1) element.imagen = await this.changeHttps(element.imagen);
        element.tipo = 'YoAprendo';
      });
      success(videos)
    });
  }
  public getThumb(url) {
    return new Promise(success => {
      var video, results, thumburl;
      if (url === null) {
        success('');
      }
      results = url.match('[\\?&]v=([^&#]*)');
      video = (results === null) ? url : results[1];
      thumburl = 'http://img.youtube.com/vi/' + video + '/mqdefault.jpg';
      success(thumburl);
    })
  }
  /**
   * change http to https.
   * @param url 
   */
   public changeHttps(url) {
    return new Promise(success => {
      let urlNueva = url.replace('http', 'https');
      success(urlNueva);
    });
  }
  /**
   * Definir seo de la seccion yoAprendo
   */
  setSeo() {
    let title = 'YO FERRETERO :: La Aplicación Del Ferretero ::';
    let description = 'La primera aplicación ferretera que tiene como objetivo crear una comunidad y ser una herramienta de capacitación, actualización y entretenimiento para el sector ferretero. Puedes encontrar noticias del momento, capacitación, la guía de proveedores más completa del sector, ofertas y mucho más';
    let image = this.mainService.baseURL+'assets/img/logo-login.png';
    this.title.setTitle(title);
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:image:alt', content: title });
    this.meta.addTag({ property: 'og:image:alt', content: title });
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ name: 'title', content: title });
    this.meta.addTag({ itemprop: 'name', content: title });

    this.meta.addTag({ name: 'twitter:image', content: image });
    this.meta.addTag({ itemprop: 'image', content: image });

    this.meta.addTag({ name: 'twitter:description', content: description });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ itemprop: 'description', content: description });
    this.meta.addTag( {name: 'keywords', content: 'Yo ferretero, Ferretero, APLICACIÓN para ferreterías, YOFERRETERO, Ferretería, Guía proveedores, Herramientas, Ferretería, Ferretero millonario, Horóscopo, Horóscopo ferretero, Noticias ferretería, Noticias sector ferretero, Aplicación yoferretero, Quien quiere ser millonario ferretería'})

    this.meta.addTag({ property: 'og:image', itemprop:"image",content: image });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ property: 'og:image:width', content: '300' });
    this.meta.addTag({ property: 'og:image:height', content: '200' });
    this.meta.addTag({ property: 'og:url', content: 'https://www.yoferretero.com/' });
    this.meta.addTag({ itemprop:"thumbnailUrl",content: image });
  }

  /**
   * Obtiene el usuario activo para verificar si la noticia fue guardada o le dieron like
   */
   getUsuario() {
    this.mainService
      .get("api/user/" + this.usuarioLocal._id)
      .subscribe( (user: UsuarioInterface) => {
        this.emisorUsuario.next(user);
        this.usuario = user;
        this.usuarioCargado = true;
      });
  }
  /** 
   * Metodo para incrementar la cantidad de Videos a ver
   */
   vermas() {
    this.contVermas = this.contVermas + 1;
    let van = this.cantVideos * this.contVermas;
    if (van >= this.totalVideos) {
      this.mostrarVerMas = false;
    }
  }

}
