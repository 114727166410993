<div class="seccion-noticias">
    <div class="row ">
      <div class="col-12">
        <div class="row">
          <div class="col-10 ">
            
            <div (click)="redirectTo({slug:item.slug})"
              class="texto-info3">
              {{ item.titulo  }}
            </div>
            
            <div class="row">
              <div class="col-1 ">
                <fa-icon class="icon-clock float-left" [icon]="faClock"> </fa-icon>
              </div>
              <div class="col-1 ">
                <div
                  class="texto-info2 my-auto">
                  {{item.fechaPublicacion  | datediff}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-2 col-sm-2 ">
          </div>
        </div>
      </div>
    </div>
  </div>
  