import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-tarjeta-video',
  templateUrl: './tarjeta-video.component.html',
  styleUrls: ['./tarjeta-video.component.scss']
})
export class TarjetaVideoComponent implements OnInit {
  /** item con el detalle del horoscopo*/
  @Input() item;
  /** icono de la flecha*/
  faArrowRight = faArrowRight;
  constructor(private router: Router,) {
    
   }

  ngOnInit(): void {
  }
  /**
   * Redirije a la vista de detalle de noticia
   * @param param0  indica el id de la noticia a mostrar
   */
   onDetalleNoticia({ id }) {
    this.router.navigate(["/video", id]);
  }

}
