import { OfertasInterface } from './../../../models/ofertas';
import { Component, OnInit, Input, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import { UsuarioInterface } from 'src/app/models/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { PuntosInterface } from 'src/app/models/puntos';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { LinkService } from 'src/app/services/link.service';

@Component({
  selector: 'detalle-oferta',
  templateUrl: './detalle-oferta.component.html',
  styleUrls: ['./detalle-oferta.component.scss']
})
export class DetalleOfertaComponent implements OnInit {
  /** Variable para verificar cuando se loguee el usuario */
  usuarioLogueado = false;
  /** Objeto usuario local storage*/
  public usuarioLocal: UsuarioInterface = null;
  /** usuario activo */
  usuario: UsuarioInterface;
  /** observable de oferta guardadas */
  @Input() public obserOferta: Observable<OfertasInterface>;
  /** Datos de la oferta a mostrar */
  oferta: OfertasInterface;
  /** variable para ocultar y mostrar condiciones */
  condiciones: boolean = false;
  /** Bandera para pantalla de solicitar */
  solicitar: boolean = false;
  /** Bandera para verificar si la oferta fue guardada por el usuario */
  save: boolean = false;
  /** Bandera para verificar si la oferta tiene like por parte del usuario*/
  like: boolean = false;
  /** Puntos cuando se guarda */
  puntosGuardar: number = 10;
  /** Puntos cuando se da like */
  puntosLike: number = 5;
  /** Variable de control para el loading */
  loading: any;
  /** icono de la flecha*/
  faArrowLeft = faArrowLeft;
  /** variable de control para salir del detalle */
  @Output() public volver = new EventEmitter<boolean>();
  /** variable de control para salir del detalle */
  @Output() public modificado = new EventEmitter<boolean>();
  /** Objeto de parametros fijos para el formulario de contacto */
  datosEnvio: any;
  /** Bandera para saber si se esta modificando la oferta */
  modificando = false;
  /** Lista de imagens de la primera carga */
  imagenesCargadas = [];
  /** Verifica si se cargo las imagenes */
  cargoImagen = false;
  /** variable de control para mostrar y ocultar modal de login */
  modalCompartir: boolean = false;
  /** Almacena el id de la oferta activa */
  public id: any;
  /** Verifica carga de datos */
  cargado = false;
  /** url auxiliar para el seo */
  miniatura: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private title: Title, private meta: Meta,
    private linkService: LinkService,
    public sanitizer: DomSanitizer,
    public mainService: MainService,
    public authService: AuthService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    public utilService: UtilService
  ) {
    this.usuarioLocal = this.authService.getUser();
    this.miniatura = this.sanitizer.bypassSecurityTrustResourceUrl('http://schema.org/ImageObject');
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.utilService.startLoading();
      this.getOferta();

    });
    this.authService.logueado.subscribe(logueado => {
      this.usuarioLogueado = logueado;
    });
  }
  /**
   * Obtener datos de la oferta
   */
  getOferta() {
    this.mainService
      .get('api/ofertasSlug/' + this.id)
      .subscribe((oferta: OfertasInterface) => {
        this.oferta = oferta;
        this.setSeo('YoFerretero::Ferreofertas', this.oferta.nombre, this.oferta.imagenes[0].url);
        if (isPlatformBrowser(this.platformId)) {
          this.imagenesCargadas = this.oferta.imagenes;
          if (this.usuarioLogueado) {
            this.getUsuario();
          } else {
            this.cargado = true;
          }
          this.guardarVisita();
        }
      });

  }
  /**
   * Establece los patametros del SEO para la oferta
   */
  setSeo(title, description, image) {
    this.linkService.addTag( { rel: 'image_src', href: image });
    this.title.setTitle(description);
    this.meta.addTag({ property: 'og:title', content: title }, true);
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:image:alt', content: title });
    this.meta.addTag({ property: 'og:image:alt', content: title });
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:site_name', content: title });
    this.meta.addTag({ name: 'title', content: title });
    this.meta.addTag({ itemprop: 'name', content: title });
    this.linkService.addTag({ rel: 'image_src', href: image });
    this.meta.addTag({ name: 'twitter:image', content: image });
    this.meta.addTag({ itemprop: 'image', content: image });
    this.meta.addTag({ property: 'og:image', content: image });

    this.meta.addTag({ name: 'twitter:description', content: description });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({
      name: 'description',
      property: 'og:description',
      content: description,
    });
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ itemprop: 'description', content: description });

    //this.meta.addTag({ property: 'og:image', itemprop:"image",content: image });
    this.meta.addTag({ property: 'og:type', content: 'article' });
    this.meta.addTag({
      property: 'twitter:card',
      content: 'summary_large_image',
    });
    this.meta.addTag({ property: 'og:image:width', content: '300' });
    this.meta.addTag({ property: 'og:image:height', content: '200' });
    this.meta.addTag({
      property: 'og:url',
      content: 'https://www.yoferretero.com/detalle-oferta/'+this.oferta.slug,
    });
    this.meta.addTag({ property: 'og:image:secure_url', content: image });
    this.meta.addTag({ name: 'msapplication-TileImage', content: image });
  }
  /**
   * Metodo para guardar la visita del usuario
   */
  guardarVisita() {
    if (isPlatformBrowser(this.platformId)) {
      let user = "invitado";
      if (this.usuarioLogueado) {
        user = this.usuarioLocal._id;
      }
      let visita = {
        plataforma: 'web',
        usuario: user,
        modulo: 'oferta',
        oferta: this.oferta._id
      };
      this.utilService.guardarVisita(visita);
    }
  }
  /** Volver a la lista de ofertas */
  onVolver() {
    this.cargoImagen = false;
    //this.volver.emit(true);
    this.router.navigate(['/ferreofertas']);
  }
  /**
   * Obtiene el usuario activo para verificar si la noticia fue guardada o le dieron like
   */
  getUsuario() {
    if (isPlatformBrowser(this.platformId)) {
      this.mainService
        .get('api/user/' + this.usuarioLocal._id)
        .subscribe(user => {
          this.usuario = user;
          if (
            this.usuario.ofertasGuardadas === undefined ||
            this.usuario.ofertasGuardadas === null
          ) {
            this.usuario.ofertasGuardadas = [];
          }
          if (
            this.usuario.ofertasGuardadas === undefined ||
            this.usuario.ofertasGuardadas === null
          ) {
            this.usuario.ofertasGuardadas = [];
          }
          if (
            this.usuario.ofertasLike === undefined ||
            this.usuario.ofertasLike === null
          ) {
            this.usuario.ofertasLike = [];
          }
          if (
            this.usuario.ofertasLike === undefined ||
            this.usuario.ofertasLike === null
          ) {
            this.usuario.ofertasLike = [];
          }
          let findSave = this.usuario.ofertasGuardadas.indexOf(this.oferta._id);
          if (findSave !== -1) {
            this.save = true;
          }
          let findLike = this.usuario.ofertasLike.indexOf(this.oferta._id);
          if (findLike !== -1) {
            this.like = true;
          }
          this.datosEnvio = {
            to: this.oferta.email,
            subject: 'Cotización FerreOferta - YoFerretero',
            titulo: 'Cotización',
            evento: 'Solicitar cotización oferta',
            referencia: this.oferta._id,
            nombreOferta: this.oferta.nombre,
            tipo: 'cotizacion'
          };
          this.cargado = true;
        });
    }
  }
  /**
   *
   */
  slideImageChanged() {
    //console.log("Slide imagnes")
  }

  /**
   * Metodo para agregar y quitar ofertas de los guardados del usuario
   */
  toogleSave() {
    if (!this.usuarioLogueado) {
      this.utilService.showWarning({
        time: 5000,
        title: 'Alerta',
        msj: 'Inicia sesión para guardar la oferta!'
      });
      return false;
    }
    if (this.save) {
      let findSave = this.usuario.ofertasGuardadas.indexOf(this.oferta._id);
      this.usuario.ofertasGuardadas.splice(findSave, 1);
      this.usuario.puntos = this.usuario.puntos - this.puntosGuardar;
      this.quitarFavorito();
    } else {
      this.usuario.ofertasGuardadas.push(this.oferta._id);
      this.usuario.puntos = this.usuario.puntos + this.puntosGuardar;
      this.guardarFavorito();
    }
    this.save = !this.save;
  }
  /**
   * Metodo para agregar al modelo de oferta guardadas por  el usuario
   */
  guardarFavorito() {
    let data = {
      tipo: 'oferta',
      usuario: this.usuario._id,
      oferta: this.oferta._id
    };
    this.mainService.post('api/saved', data).subscribe(result => {
      let puntuacion: PuntosInterface = {
        evento: 'Agregar oferta a favoritos',
        referencia: this.oferta._id,
        usuario: this.usuario._id,
        puntos: this.puntosGuardar
      };
      this.utilService.guardarHistorialDePuntos(puntuacion);
      this.modificarUsuario('save');
    });
  }
  /**
   * Metodo para quitar al modelo de ofertas guardadas por  el usuario
   */
  quitarFavorito() {
    this.mainService
      .delete('api/savedOferta/' + this.usuario._id + '/' + this.oferta._id)
      .subscribe(result => {
        let puntuacion: PuntosInterface = {
          evento: 'Quitar oferta de favoritos',
          referencia: this.oferta._id,
          usuario: this.usuario._id,
          puntos: this.puntosGuardar * -1
        };
        this.utilService.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('save');
      });
  }
  /**
   * Metodo para agregar y quitar like de la ofertas por el usuario
   */
  toogleLike() {
    if (!this.usuarioLogueado) {
      this.utilService.showWarning({
        time: 5000,
        title: 'Alerta',
        msj: 'Inicia sesión para dar like en la oferta!'
      });
      return false;
    }
    if (this.like) {
      let findLike = this.usuario.ofertasLike.indexOf(this.oferta._id);
      this.usuario.ofertasLike.splice(findLike, 1);
      this.usuario.puntos = this.usuario.puntos - this.puntosLike;
      this.quitarLike();
    } else {
      this.usuario.ofertasLike.push(this.oferta._id);
      this.usuario.puntos = this.usuario.puntos + this.puntosLike;
      this.guardarLike();
    }
    this.like = !this.like;
  }
  /**
   * Metodo para agregar al modelo de oferta guardadas por  el usuario
   */
  guardarLike() {
    let likes = 0;
    if (this.oferta.likes !== undefined && this.oferta.likes !== null) {
      likes = this.oferta.likes;
    }
    likes++;
    let ofertaUpdate = {
      likes: likes
    };
    this.mainService
      .put('api/ofertas/' + this.oferta._id, ofertaUpdate)
      .subscribe(result => {
        let puntuacion: PuntosInterface = {
          evento: 'Like en oferta',
          referencia: this.oferta._id,
          usuario: this.usuario._id,
          puntos: this.puntosLike
        };
        this.utilService.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('like');
        this.oferta.likes++;
      });
  }
  /**
   * Metodo para quitar like de oferta y del usuario
   */
  quitarLike() {
    let likes = 0;
    if (this.oferta.likes !== undefined && this.oferta.likes !== null) {
      likes = this.oferta.likes;
    }
    likes--;
    let ofertaUpdate = {
      likes: likes
    };
    this.mainService
      .put('api/ofertas/' + this.oferta._id, ofertaUpdate)
      .subscribe(result => {
        let puntuacion: PuntosInterface = {
          evento: 'Quitar Like en oferta',
          referencia: this.oferta._id,
          usuario: this.usuario._id,
          puntos: this.puntosLike * -1
        };
        this.utilService.guardarHistorialDePuntos(puntuacion);
        this.modificarUsuario('like');
        this.oferta.likes--;
      });
  }
  /**
   * Actualizar datos del local storage de usuario
   */
  updateStorage() {
    let token = this.authService.getToken();
    this.authService.storeUserData(token, this.usuario);
    //this.modificado.emit(true);
  }
  /**
   * Emite que se envio la cotizacion
   * @param event Valor a emitir a pantalla padre
   */
  onEnviado(event) {
    this.solicitar = false;
  }
  modificarUsuario(tipo) {
    this.mainService
      .put('api/user/' + this.usuario._id, this.usuario)
      .subscribe((result: any) => {
        if (result.success) {
          this.updateStorage();
        }
      });
  }
  /**
   * Genera enlace para compartir en redes sociales
   */
  enlaceShared() {
    return this.mainService.baseURLssr + 'detalle-oferta/' + this.oferta.slug;
  }
   /**
   * Evento al compartir
   */
  compartiendo(){
    let puntuacion: PuntosInterface = {
      evento: "Compartir oferta",
      referencia: this.oferta._id,
      usuario: this.usuario._id,
      puntos: 5
    };
    this.utilService.guardarHistorialDePuntos(puntuacion);
  }
}
