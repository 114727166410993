<main role="main" class="fondo">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.1)"
    size="medium"
    color="#fff"
    type="square-jelly-box"
    [fullScreen]="true"
    ><p class="texto-blanco">Espere por favor...</p></ngx-spinner
  >
  <div class="container mx-auto marginT-20 marginB-20" *ngIf="cargaCompleta">
    <div class="row">
      <div class="col-12">
        <h3>Todos los proveedores que necesitas a tu alcance</h3>
        <p class="menu-categorias d-lg-none" (click)="toggleSidebar()" ><span *ngIf="!opened" >Categorias</span><span *ngIf="opened" >Proveedores</span></p>
      </div>
    </div>
    <ng-sidebar-container class="sidebar-container side-proveedor marginT-20">
      <ng-sidebar [(opened)]="opened" mode="slide" [dockedSize]="'50px'">
        <div class="header-menu">
          <label>Categorias</label>
          <div class="row">
            <div class="col-12 d-flex">
              <input
                type="search"
                class="form-control pl-23"
                [(ngModel)]="textoBuscar"
                [disabled]="buscando"
              />
              <i class="fa fa-search icono-buscar"></i>

              <button
                type="button"
                class="boton-iniciar"
                (click)="buscar()"
                [hidden]="buscando"
                expand="block"
              >
                Buscar
              </button>
              <button
                type="button"
                expand="block"
                class="boton-iniciar"
                (click)="resetearBusqueda()"
                [hidden]="!buscando"
              >
                Limpiar
              </button>
            </div>
          </div>
        </div>

        <div [hidden]="buscando">
            <div
            *ngFor="let item of categorias; let i = index"
            [ngClass]="{
              activo:   item._id === idCategoriaSeleccionada,
              'item-menu': item._id !== idCategoriaSeleccionada
            }"
            (click)="seleccionar({ id: item._id, pos: i })"
          >
            {{ item.nombre }}
          </div>
        </div>
        <div [hidden]="!buscando">
            <div
            *ngFor="let item of categoriasEncontradas; let i = index"
            [ngClass]="{
              activo:   item._id === idCategoriaSeleccionada,
              'item-menu': item._id !== idCategoriaSeleccionada
            }"
            (click)="seleccionar({ id: item._id, pos: i })"
          >
            {{ item.nombre }}
          </div>
        </div>

      </ng-sidebar>
      <!-- Page content -->
      <div ng-sidebar-content class="content-side-bar">
        <div [hidden]="buscando && !selectCateBusqueda">
          <div class="row" *ngIf="categoriaSeleccionada">
            <div class="col-12 mb-20">
              <label class="texto-categoria">{{
                categoriaSeleccionada.nombre
              }}</label>
            </div>
          </div>
          <div class="contenedor">
            <div class="row" [hidden]="listaSeleccionada.length > 0">
              <div class="col-12">
                <label class="texto-destacada"
                  >No se encontraron proveedores</label
                >
              </div>
            </div>
            <div class="row marginT-20" *ngIf="listaSeleccionadaDestacada.length > 0">
              <div
                class="col-12"
              >
                <label class="texto-destacada">Marcas destacadas</label>
              </div>
              <div class="col-12 col-sm-8 col-md-8 col-lg-6 marginB-12" *ngFor="let prov of listaSeleccionadaDestacada">
                <tarjeta-proveedor
                  [proveedor]="prov"
                  (contactar)="onContactar($event)"
                ></tarjeta-proveedor>
              </div>
            </div>
            <div class="row marginT-20" *ngIf="listaSeleccionada.length > 0">
                <div
                  class="col-12"
                >
                  <label class="texto-destacada">Marcas</label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 marginB-12" *ngFor="let prov of listaSeleccionada">
                  <tarjeta-proveedor
                    [proveedor]="prov"
                    (contactar)="onContactar($event)"
                  ></tarjeta-proveedor>
                </div>
              </div>
          </div>
        </div>
        <div [hidden]="!buscando || selectCateBusqueda">
          <div class="row" >
            <div class="col-12 mb-20">
              <label class="texto-categoria">Resultado de la busqueda</label>
            </div>
          </div>
          <div class="contenedor">
            <div class="row" [hidden]="proveedoresEncontrados.length > 0">
              <div class="col-12">
                <label class="texto-destacada"
                  >No se encontraron proveedores</label
                >
              </div>
            </div>
            <div class="row marginT-20" *ngIf="listaEncontradaDestacada.length > 0">
              <div
                class="col-12"
              >
                <label class="texto-destacada">Marcas destacadas</label>
              </div>
              <div class="col-12 col-sm-8 col-md-8 col-lg-6 marginB-12" *ngFor="let prov of listaEncontradaDestacada">
                <tarjeta-proveedor
                  [proveedor]="prov"
                  (contactar)="onContactar($event)"
                ></tarjeta-proveedor>
              </div>
            </div>
            <div class="row marginT-20" *ngIf="proveedoresEncontrados.length > 0">
                <div
                  class="col-12"
                >
                  <label class="texto-destacada">Marcas</label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 marginB-12" *ngFor="let prov of proveedoresEncontrados">
                  <tarjeta-proveedor
                    [proveedor]="prov"
                    (contactar)="onContactar($event)"
                  ></tarjeta-proveedor>
                </div>
              </div>
          </div>
        </div>
      </div>
    </ng-sidebar-container>
  </div>
  <div [hidden]="!solicitar" *ngIf="usuarioLocal && solicitar">
    <modal-contacto
      [param]="datosEnvio"
      [usuario]="usuarioLocal"
      (enviado)="onEnviado($event)"
    ></modal-contacto>
  </div>
</main>
