import {Component, Input, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'horoscopo-detalle',
  templateUrl: './horoscopo-detalle.component.html',
  styleUrls: ['./horoscopo-detalle.component.css']
})
export class HoroscopoDetalleComponent implements OnInit {

  /** item con el detalle del horoscopo*/
  @Input() item;
  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

}
