<div class="contenedor-tarjeta">
  <div class="contenedor-tarjeta-interno">
    <div class="row">
      <div class="col-4">
        <div
          class="icono-signo my-auto"
          [ngStyle]="{ 'background-image': 'url(' + item.icono2 + ')' }"
        ></div>
      </div>
      <div class="col-8">
        <div class="texto-titulo">
          {{ item.nombre }}
        </div>
        <div class="texto-info1">
          {{ item.rango }}
        </div>
        <div class="texto-info2">
          Número de la suerte: {{ item.numeroSuerte }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div
          class="texto-info3"
          [innerHtml]="sanitizer.bypassSecurityTrustHtml(item.descripcion)"
        ></div>
      </div>
    </div>
  </div>
</div>
