import { MainService } from 'src/app/services/main.service';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { PuntosInterface } from '../models/puntos';
import { VisitaInterface } from '../models/visita';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  /** Configurar email de envio de formularios */
  adminEmail = "yoferretero@agenciab2b.co";
  adminEmailCC = "natacm123@hotmail.com";
  /** Variable de almacenamiento de datos de consultas */
  dataNoticias: any[];
  dataOfertas: any[];
  dataVideos: any[];
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public mainService: MainService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.dataNoticias = JSON.parse(localStorage.getItem('noticiasYFweb')) || {};
      this.dataOfertas = JSON.parse(localStorage.getItem('ofertasYFweb')) || {};
    }
   }

  /**
   * Metodo para guardar el eventos y los puntos del usuario
   * @param puntuacion objeto de puntuacion para el historia de puntos del usuario
   */
  guardarHistorialDePuntos(puntuacion: PuntosInterface) {
    //console.log('Guardo historial de puntos');
    this.mainService.post('api/puntos', puntuacion).subscribe(result => {
      console.log('Guardo historial de puntos');
    });
  }
  /**
   * Metodo para guardar visitas dependiendo del modulo
   * @param visita objeto con los datos del usuario y modulo visitado
   */
  guardarVisita(visita: VisitaInterface) {
    this.mainService.post('api/visita', visita).subscribe(result => {
      // console.log('Guardo visita del usuario');
    });
    if(visita.noticia !== undefined){
      let idNoticia = visita.noticia;
      this.mainService.put('api/noticiaGuardarVisita', { noticia: idNoticia}).subscribe(result => {
        // console.log(result);
      });
    } else if (visita.video !== undefined){
      let idVideo = visita.video;
      this.mainService.put('api/yoAprendoGuardarVisita', { video: idVideo}).subscribe(result => {
        // console.log(result);
      });
    }
  }
  /**
   * Mostrar cargando
   */
  startLoading() {
    this.spinner.show();
  }
  /**
   * Mostrar cargando
   */
  stopLoading() {
    this.spinner.hide();
  }
  /**
   * Muestra mensaje de exito
   * @param param0 Mensaje, titulo y tiempo del mensaje del toash
   */
  showSuccess({ msj, title, time }) {
    this.toastr.success(msj, title, {
      timeOut: time,
    });
  }
  /**
   * Muestra mensaje de error
   * @param param0 Mensaje, titulo y tiempo del mensaje del toash
   */
  showError({ msj, title, time }) {
    this.toastr.error(msj, title, {
      timeOut: time,
    });
  }
  /**
   * Muestra mensaje de alerta
   * @param param0 Mensaje, titulo y tiempo del mensaje del toash
   */
  showWarning({ msj, title, time }) {
    this.toastr.warning(msj, title, {
      timeOut: time,
    });
  }


  /**
   * Almacena las noticias en el localStorage
   * @param noticias Objeto de noticias
   */
  public storeNoticias(noticias) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('noticiasYFweb', JSON.stringify(noticias));
      this.dataNoticias = noticias;
    }
    return true;
  }
  /**
   * Carga la informacion almacena de las noticias
   */
  public loadNoticias() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.noticiasYFweb != undefined && localStorage.noticiasYFweb != null && localStorage.noticiasYFweb != 'null') {
        return localStorage.noticiasYFweb
      } else {
        return false;
      }
    }
  }
  /**
   * Almacena las ofertas en el localStorage
   * @param ofertas Objeto de ofertas
   */
  public storeOfertas(ofertas) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('ofertasYFweb', JSON.stringify(ofertas));
    }
    this.dataOfertas = ofertas;
    return true;
  }
  /**
   * Carga la informacion almacena de las ofertas
   */
  public loadOfertas() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.ofertasYFweb != undefined && localStorage.ofertasYFweb != null && localStorage.ofertasYFweb != 'null') {
        return localStorage.ofertasYFweb
      } else {
        return false;
      }
    }
  }
  /**
   * Almacena los proveedores en el localStorage
   * @param proveedores Objeto de proveedores
   */
  public storeProveedores(proveedores) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('proveedoresYFweb', JSON.stringify(proveedores));
    }
    return true;
  }
  /**
   * Carga la informacion almacena de los proveedores
   */
  public loadProveedores() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.proveedoresYFweb != undefined && localStorage.proveedoresYFweb != null && localStorage.proveedoresYFweb != 'null') {
        return localStorage.proveedoresYFweb
      } else {
        return false;
      }
    }
  }
  /**
   * Almacena los horoscoos en el localStorage
   * @param horoscopos Objeto de horoscopos
   */
  public storeHoroscopo(horoscopos) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('horoscopoYFweb', JSON.stringify(horoscopos));
    }
    return true;
  }
  /**
   * Carga la informacion almacena de los horoscopos
   */
  public loadHoroscopo() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.horoscopoYFweb != undefined && localStorage.horoscopoYFweb != null && localStorage.horoscopoYFweb != 'null') {
        return localStorage.horoscopoYFweb
      } else {
        return false;
      }
    }
  }
  /**
   * Almacena las categorias en el localStorage
   * @param categorias Objeto de categorias
   */
  public storeCategorias(categorias) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('categoriasYFweb', JSON.stringify(categorias));
    }
    return true;
  }
  /**
   * Carga la informacion almacena de los categorias
   */
  public loadCategorias() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.categoriasYFweb != undefined && localStorage.categoriasYFweb != null && localStorage.categoriasYFweb != 'null') {
        return localStorage.categoriasYFweb
      } else {
        return false;
      }
    }
  }
  /**
   * Almacena las banner de bienvenida en el localStorage
   * @param banner Objeto banner
   */
  public storeBanner(banner) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('bannerYFweb', JSON.stringify(banner));
    }
    return true;
  }
  /**
   * Carga la informacion almacena del banner
   */
  public loadBanner() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.bannerYFweb != undefined && localStorage.bannerYFweb != null && localStorage.bannerYFweb != 'null') {
        return localStorage.bannerYFweb;
      } else {
        return false;
      }
    }
  }
  /**
   * Almacena las videos yoaprendo en el localStorage
   * @param videos Objeto de videos
   */
   public storeVideos(videos) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('videosYFweb', JSON.stringify(videos));
    }
    this.dataVideos = videos;
    return true;
  }
  /**
   * Carga la informacion almacena de los videos
   */
  public loadVideos() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.videosYFweb != undefined && localStorage.videosYFweb != null && localStorage.videosYFweb != 'null') {
        return localStorage.videosYFweb
      } else {
        return false;
      }
    }
  }
  getItem(cookies, sKey): string {
    if (!sKey) {
        return null;
    }
    return decodeURIComponent(cookies.replace(new RegExp(
        '(?:(?:^|.*;)\\s*' +
        encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
        '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1'
    )
    ) || null;
}
}
