import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalLoginComponent } from './../pages/Components/modal-login/modal-login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {RouterModule} from "@angular/router";
import { ReactiveFormsModule } from '@angular/forms';
import { ModalCrearComponent } from '../pages/Components/modal-crear/modal-crear.component';
import { ModalContactoHomeComponent } from '../pages/Components/modal-contacto-home/modal-contacto-home.component';
import { ModalRecuperarPasswordComponent } from '../pages/Components/modal-recuperar-password/modal-recuperar-password.component';
import {FormsModule} from '@angular/forms';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

@NgModule({
  declarations: [HeaderComponent, ModalContactoHomeComponent, FooterComponent, ModalLoginComponent, ModalCrearComponent,ModalRecuperarPasswordComponent],
  exports: [HeaderComponent, ModalContactoHomeComponent, FooterComponent, ModalLoginComponent, ModalCrearComponent,ModalRecuperarPasswordComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    FormsModule,
    AutocompleteLibModule
  ]
})
export class SharedModule { }
