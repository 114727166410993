import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { BannerInterface } from 'src/app/models/banner';
import { MainService } from "src/app/services/main.service";
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
declare const $: any;
declare interface RouteInfo {
  value: string;
  titulo: string;
  url: string;

}
export const ROUTES: RouteInfo[] = [
  { value: "1", titulo: "Actualidad", url: "/actualidad" },
  // { value: "2", titulo: "Ferreofertas", url: "/ferreofertas" },
  { value: "3", titulo: "Proveedores", url: "/proveedores" },
  { value: "4", titulo: "Juego", url: "/juego" },
  // { value: "5", titulo: "Horóscopo", url: "/horoscopo" },
  { value: "6", titulo: "YoAprendo", url: "/yoaprendo" },
];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  /** Datos de premio del mes desde base de datos */
  premio: BannerInterface;
  /** Verifica si el usuario esta logueado */
  usuarioLogueado = false;
  /** Almacena los items del menu */
  menuItems: any[];
  /** fecha actual */
  hoy: any;
  test: any = "https://i.ibb.co/3SfSTT1/Imagen-premio-julio-3.jpg";
  /** Variable de control para determinar que los datos fueron cargados */
  cargaCompleta = false;
  /** Variable de control para determinar que los datos fueron cargados */
  cargado = false;
  /** variable de control para mostrar y ocultar modal de login */
  modalLogin: boolean = false;
  /** variable de control para mostrar y ocultar modal de olvidar */
  modalOlvidar: boolean = false;
  /** variable de control para mostrar y ocultar modal de registro */
  modalCrear: boolean = false;
  /** variable de control para mostrar y ocultar modal de contacto */
  modalContacto: boolean = false;
  /** Configuración de los datos de envio */
  datosEnvio: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public utilService: UtilService,
    public mainService: MainService,
    public authService: AuthService) {
    var fecha = new Date();
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    // @ts-ignore
    this.hoy = fecha.toLocaleDateString("es-ES", options)
    this.authService.logueado.subscribe(logueado => {
      this.usuarioLogueado = logueado;
    });
    this.datosEnvio = {
      to: this.utilService.adminEmail,
      subject: 'Formulario de contacto - YoFerretero',
      titulo: 'Contacto',
      evento: 'Contactar',
      referencia: 'Formulario-contacto'
    }
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.getPremio()
  }
  /**
   * Metodo para ocultar menu de navegacion en vista responsive
   */
  toggleNavbar() {
    if (isPlatformBrowser(this.platformId)) {
      let element: HTMLElement = document.getElementById('botonMenu') as HTMLElement;
      element.click();
    }
  }
  /**
   * Obtener datos del premio del mes
   */
  getPremio() {
    this.mainService.get('api/banner').subscribe((banners: BannerInterface[]) => {
      for(let banner of banners){
        if(banner.nombre === 'Premio del juego web'){
          this.premio = banner;
          this.cargado = true
        }
      }
    });
  }
  /**
   * Ocultar login desde el componente modal-login
   * @param event disparador del evento
   */
  onCerrarLogin(event) {
    this.modalLogin = event;
  }
  /**
   * Ocultar contacto desde el componente modal-login
   * @param event disparador del evento
   */
  onCerrarContacto(event) {
    this.modalContacto = event;
  }
  /**
   * Ocultar contacto desde el componente modal-login
   * @param event disparador del evento
   */
  onCerrarOlvidar(event) {
    this.modalOlvidar = event;
  }
  /**
   * Mostrar modal de registro de usuario con disparador desde el modal de login de usuario
   * @param event Disparador del evento
   */
  onRegistroLogin(event) {
    this.modalLogin = false;
    this.modalCrear = true;
  }
  /**
   * Mostrar modal de olvidar la contraseña de usuario con disparador desde el modal de login de usuario
   * @param event Disparador del evento
   */
  onLoginOlvidar(event) {
    this.modalLogin = false;
    this.modalOlvidar = true;
  }
  /**
  * Ocultar login desde el componente modal-login
  * @param event disparador del evento
  */
  onCerrarCrear(event) {
    this.modalCrear = event;
  }
  /**
   * Metodo para abrir enlace externos
   */
  enlaceExterno(url) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(url, '_blank');
    }
  }

}
