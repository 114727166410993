import { UtilService } from './../../services/util.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from "@angular/router";
import { MainService } from "../../services/main.service";
import { HoroscopoInterface } from "src/app/models/horoscopo";
import { AuthService } from "../../services/auth.service";
import * as _ from "lodash";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { UsuarioInterface } from 'src/app/models/user';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-horoscopo',
  templateUrl: './horoscopo.component.html',
  styleUrls: ['./horoscopo.component.scss']
})
export class HoroscopoComponent implements OnInit {
  /** Variable para verificar cuando se loguee el usuario */
  usuarioLogueado = false;
  /** Datos de usuario */
  usuarioLocal: UsuarioInterface;
  /** Bandera para determinar loadig de carga de datos*/
  cargado: boolean = false;
  /** Arreglo que almacena la lista de signos del horocopo */
  listaSignos: HoroscopoInterface[] = [];
  /** booleano que indica si se esta viendo el detalle de algun signo*/
  detalle = false;
  /** item con el detalle del horoscopo*/
  hSeleccionada: any;
  /** icono de la flecha*/
  faArrowLeft = faArrowLeft;
  /** Objeto de horoscopo desde localStorage */
  storeHoroscopo: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private title: Title, private meta: Meta,
    public sanitizer: DomSanitizer,
    public mainService: MainService,
    public authService: AuthService,
    public utilService: UtilService,
    public router: Router
  ) {
    this.usuarioLocal = this.authService.getUser();
    this.authService.logueado.subscribe(logueado => {
      this.usuarioLogueado = logueado;
      if (logueado) {
        this.usuarioLocal = this.authService.getUser();
      }
    });
    //this.utilService.startLoading();
    if (isPlatformBrowser(this.platformId)) {
      this.storeHoroscopo = this.utilService.loadHoroscopo();
      if (this.storeHoroscopo !== false) {
        this.listaSignos = JSON.parse(this.storeHoroscopo);
        let self = this;
        setTimeout(function () {
          self.cargado = true;
          //self.utilService.stopLoading;
        }, 100);
      }
    }
  }

  ngOnInit() {

    this.setSeo('YO FERRETERO :: La Aplicación Del Ferretero ::Horoscopo', 'Espacio de ocio que motiva a la interacción con el medio, aquí encontraras el numero de la suerte,el color de la semana y lo que te dicen los astros.', this.mainService.baseURL + 'assets/img/logo-login.png');
    this.getSignos();
  }

  getSignos() {
    let now = new Date();
    this.mainService.get("api/horoscopo").subscribe((signos: HoroscopoInterface[]) => {
      if (this.storeHoroscopo === JSON.stringify(signos)) {
      } else {
        this.listaSignos = signos;
        if (isPlatformBrowser(this.platformId)) {
          this.utilService.storeHoroscopo(signos);
        }
      }
      if (!this.cargado) {
        this.cargado = true;
        //this.utilService.stopLoading();
      }
    });
  }

  seleccionar({ pos }) {
    this.hSeleccionada = this.listaSignos[pos];
    this.detalle = true;
    this.guardarVisita();
  }

  onVolver() {
    this.detalle = false;
  }
  /**
   * Metodo para guardar la visita del usuario
   */
  guardarVisita() {
    let user = "invitado";
    if (this.usuarioLogueado) {
      user = this.usuarioLocal._id;
    }
    let visita = {
      plataforma: "web",
      usuario: this.usuarioLocal._id,
      modulo: "horoscopo",
      horoscopo: this.hSeleccionada._id
    };
    this.utilService.guardarVisita(visita);
  }
  /**
   * Establece los patametros del SEO para la oferta
   */
  setSeo(title, description, image) {
    this.title.setTitle(title);
    this.meta.addTag({ property: 'og:title', content: '' }, true);
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:image:alt', content: title });
    this.meta.addTag({ property: 'og:image:alt', content: title });
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ name: 'title', content: title });
    this.meta.addTag({ itemprop: 'name', content: title });

    this.meta.addTag({ name: 'twitter:image', content: image });
    this.meta.addTag({ itemprop: 'image', content: image });
    this.meta.addTag({ property: 'og:image', content: image });

    this.meta.addTag({ name: 'twitter:description', content: description });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ itemprop: 'description', content: description });
    
    this.meta.addTag({ property: 'og:image', itemprop:"image",content: image });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ property: 'og:image:width', content: '300' });
    this.meta.addTag({ property: 'og:image:height', content: '200' });
    this.meta.addTag({ property: 'og:url', content: 'https://www.yoferretero.com/horoscopo' });
  }
}
