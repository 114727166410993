import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UsuarioInterface } from 'src/app/models/user';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { PuntosInterface } from 'src/app/models/puntos';
import * as moment from 'moment';
@Component({
  selector: 'modal-contacto',
  templateUrl: './modal-contacto.component.html',
  styleUrls: ['./modal-contacto.component.css']
})
export class ModalContactoComponent implements OnInit {
  /** variable de control para salir del detalle */
  @Output() public enviado = new EventEmitter<boolean>();
  /** usuario activo */
  @Input() usuario: UsuarioInterface;
  /** Objeto con los atributo to, subject y titulo */
  @Input() param;
  /** modelo para el comentario */
  comentario: string = '';
  /** Objeto para control de datos del formulario de envio */
  public frmEnvio: FormGroup;
  /** Bandera para evaluar si se esta enviando el formulario de datos */
  public submited = false;
  /** Verifica si se esta enviando el email */
  enviando = false;
  /** mostrar input de cantidad */
  mostrarUnidades = false;
  constructor(
    public mainService: MainService,
    public authService: AuthService,
    public fb: FormBuilder,
    public utilService: UtilService
  ) {
    this.frmEnvio = this.fb.group({
      nit: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      comentario: ['', [Validators.required]],
      unidades: [0],
    });
  }

  ngOnInit(): void {
    if (this.param.tipo) {
      this.mostrarUnidades = true;
    }
  }
  /**
   * Funcion para abreviar la obtencion de valores de campos del formulario
   */
  get f() {
    return this.frmEnvio.controls;
  }
  /**
   * Funcion para eviar cotización
   */
  onEnviar() {
    this.submited = true;

    if (this.frmEnvio.invalid) {
      return false;
    }

    let datos = this.frmEnvio.value;
    let mensaje =
      '<b>Nombre;</b>' +
      this.usuario.nombre +
      '<br><b>NIT:</b>' +
      datos.nit +
      '<br><b>Email: </b>' + this.usuario.email + '<br><b>Teléfono:</b>' +
      this.usuario.celular +
      '<br><b>Ciudad:</b>' +
      datos.ciudad +
      '<br><b>Comentario:</b>' +
      datos.comentario;

    if (this.mostrarUnidades) {
      mensaje = '<b>Nombre;</b>' + this.usuario.nombre + '<br><b>NIT:</b>' + datos.nit + '<br><b>Email: </b>' + this.usuario.email + '<br><b>Teléfono:</b>' + this.usuario.celular + '<br><b>Ciudad:</b>' + datos.ciudad + '<br><b>Oferta:</b>' + this.param.nombreOferta + '<br><b>Cantidad:</b>' + datos.unidades + '<br><b>Comentario:</b>' + datos.comentario;
    }
    let dataEmail = {
      to: this.param.to,
      from: this.usuario.email,
      subject: this.param.subject,//'Cotización FerreOferta - YoFerretero',
      contenido: mensaje,
      titulo: this.param.titulo,//'Cotización',
      cc: this.utilService.adminEmail
    };
    let dataValidate = {
      proveedor: this.param._id,
      user: this.usuario._id,
      date: moment().format('YYYY-MM-DD'),
    }
    this.enviando = true;
    // console.log(dataValidate);
    // console.log(this.param);
    this.utilService.startLoading();
    this.mainService.post('api/contacto_proveedor/validate', dataValidate).subscribe((response: any) => {
      if (response.success) {
        this.mainService.sendEmail(dataEmail).subscribe((res: any) => {
          if (res.success) {
            this.utilService.showSuccess({title: "¡Genial!", msj: 'Tu mensaje fue enviado, pronto se pondrán en contacto contigo.' , time: 5000})
            let puntuacion: PuntosInterface = {
              evento: this.param.evento,//'Solicitar cotización oferta',
              referencia: this.param.referencia,//this.oferta._id,
              usuario: this.usuario._id,
              puntos: 30
            };
            this.utilService.guardarHistorialDePuntos(puntuacion);
          } else {
            this.utilService.showWarning({title: "Alerta", msj: 'Ocurrio un error al enviar el correo, intentalo de nuevo.' , time: 5000})
          }
          this.enviando =  false;
          this.utilService.stopLoading();
          this.frmEnvio.reset();
          this.submited = false;
          this.comentario = '';
          this.cerrarModal(true);
        });
      } else {
        this.utilService.showError({msj: response.message, title: "Error", time: 7000});
        this.utilService.stopLoading();
        this.cerrarModal(true);
      }
    });
  }
  /**
   * Cerrar modal de contacto
   */
  cerrarModal(msj) {
    if (msj) {
      let self = this;
      setTimeout(function () {
        self.enviado.emit(true);
      }, 5000);
    } else {
      this.enviado.emit(true);
    }

  }
}
