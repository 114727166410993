<div class="card tarjeta">
  <!--<img [src]="item.imagenes[0].url" class="img-oferta" />-->
  <div class="card-header">
    <div class="imagen-principal"  [ngStyle]="{ 'background-image': 'url(' + item.imagenes[0].url + ')' }"></div>
  </div>
  <div class="descuento poppins">{{item.descuento}}% Dcto</div>
  <div class="card-content contenido-ofertas">
    <label class=" poppins ion-text-wrap texto-detalle">{{
      item.nombre.length > 70
      ? (item.nombre | slice: 0:70) + "..."
      : item.nombre
    }}</label>
  </div>
</div>
